import React, { FC, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  BottomNavigation as MUIBottomNavigation,
  BottomNavigationAction,
  makeStyles,
  Badge,
} from '@material-ui/core';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';

import { PORTAL, CASES, SETTINGS, COMPANIES } from '../../lib/config';
import { AccountBox } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  bottomNavigation: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
}));

type Props = {
  signOut: () => void;
  notifications: any;
};


export const BottomNavigation: FC<Props> = ({ notifications, }) => {
  const classes = useStyles();
  const location = useLocation();
  const [value, setValue] = React.useState('portal');

  useEffect(() => {
    setValue(location.pathname);
  }, [location]);

  return (
    <MUIBottomNavigation
      value={value}
      showLabels
      className={classes.bottomNavigation}
    >
      <BottomNavigationAction
        component={RouterLink}
        to={PORTAL}
        label="Home"
        value={PORTAL}
        icon={<HomeIcon />}
      />
      <BottomNavigationAction
        component={RouterLink}
        to={CASES}
        label="Cases"
        value={CASES}
        icon={<BusinessCenterIcon />}
      />
      <BottomNavigationAction
        component={RouterLink}
        to={COMPANIES}
        label="Companies"
        value={COMPANIES}
        icon={notifications[COMPANIES] ? <Badge badgeContent='!' variant="dot" color="error"><BusinessIcon /></Badge> : <BusinessIcon />}
      />
      <BottomNavigationAction
        component={RouterLink}
        to={SETTINGS}
        label="Profile"
        value={SETTINGS}
        icon={<AccountBox />}
      />
    </MUIBottomNavigation>
  );
};
