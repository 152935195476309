import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useAuthContext } from '../../lib/auth';
import { getClaimant, getFileById } from '../../lib/api';
import { PDFViewer } from './PDFViewer';

const pdfFile = require('./LOI.pdf');

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(6),
        },
        padding: theme.spacing(2, 3),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1, 1),
        },
    },
}));


export const Homepage = () => {
    const classes = useStyles();
    const { accessToken } = useAuthContext();
    const [claimant, setClaimant] = useState<any>(null);
    const [url, setUrl] = useState<string>(pdfFile);
    const [contractCounterSigned, setContractCounterSigned] = useState<boolean>(false);

    useEffect(() => {
        if (accessToken) {
            getClaimant(accessToken).then((data) => {
                const claimant = data.data.data;
                setClaimant(claimant);
            }).catch((error) => {
                console.log(error)
            });
        }
    }, [accessToken]);

    useEffect(() => {
        if (!claimant || !claimant.contractFileId) return;
        getFileById(accessToken, claimant.contractFileId)
            .then((res) => res.data.data.url)
            .then((url) => {
                if (url) {
                    setContractCounterSigned(true);
                    setUrl(url);
                }
            })
            .catch((err) => console.log(err));
    }, [claimant, accessToken]);



    return (
        <>
            <Paper className={classes.paper}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5" style={{ textTransform: 'uppercase' }} gutterBottom>
                            Welcome to the Homepage
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" align="center" gutterBottom>
                            {contractCounterSigned ? 'Here is the copy of the contract you signed.' : 'This is a template of the contract. You will be able to see the actual copy that you have singed once the contract is counter-signed by the GSDT.'}
                        </Typography>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Click here to read the contract.</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <PDFViewer fileURL={url} highlights={[]} />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" gutterBottom>
                            As soon as the contract is counter-signed you will revieve a notification email, as well as copy of the signed contract.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" variant="body1" style={{ textTransform: 'uppercase' }} gutterBottom>
                            We will contact you regarding next steps.
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
