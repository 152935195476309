export const getItem = (key: string): string => {
  const value = localStorage.getItem(key);
  try {
    return !!value && JSON.parse(value);
  } catch (e) {
    return value || '';
  }
};

export const setItem = (key: string, value: string) =>
  localStorage.setItem(key, JSON.stringify(value));

export const removeItem = (key: string) => localStorage.removeItem(key);

export const clear = () => localStorage.clear();
