import React, { FC, ReactNode } from "react";
import {
  Typography,
  makeStyles,
  Divider,
  Box,
  useTheme,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  slogan: {
    width: "100%",
    textAlign: "center",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    fontSize: "1rem",
  },
  sloganWrapper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  text?: string | ReactNode,
};

export const Slogan: FC<Props> = ({ text }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.sloganWrapper}>
      {typeof text === "string" ? (
        <Typography className={classes.slogan} component="p" variant="button">
          {text}
        </Typography>
      ) : (
        text
      )}
      <Divider style={{ backgroundColor: theme.palette.primary.main }} />
    </Box>
  );
};
