import React, { FC, useState, useEffect, useMemo, useCallback } from "react";

import { TextField, makeStyles, CircularProgress } from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import { UserProfileAddress } from "../../../lib/user/types";
import {
  AutoCompletePrediction,
} from "../../../lib/address";

import {
  getAddressPredictions,
} from "../../../lib/api";
import { useAuthContext } from "../../../lib/auth";

import { debounce } from "../../../lib/helpers";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 40,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    maxWidth: 276,
  },
  formGroup: {
    marginTop: theme.spacing(3),
  },
  textField: {
    marginBottom: 20,
  },
}));

type Props = {
  disabled: boolean;
  onSelect: (val: UserProfileAddress) => void;
  setIsWaitingDetails: (isWaitingDetails: boolean) => void;
  initialAddress: string;
  handleStoreUserAddress: (address: any) => void;
  setCheckingYotiAddress: (checkingYotiAddress: boolean) => void;
  address: unknown;
  hideSearch: boolean;
};
export const Address: FC<Props> = ({
  disabled,
  onSelect,
  setIsWaitingDetails,
  initialAddress,
  handleStoreUserAddress,
  setCheckingYotiAddress,
  address,
  hideSearch,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState<AutoCompletePrediction[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { accessToken } = useAuthContext();


  const setInitialAddressFromGoogleMaps = useCallback(async () => {
    if (!initialAddress) {
      setCheckingYotiAddress(false);
      return;
    }

    getAddressPredictions(accessToken as string, initialAddress)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        const predictions = response.data.predictions;
        if (Array.isArray(predictions) && predictions.length > 0) {
          handleStoreUserAddress({
            formattedAddress: predictions[0].description,
            placesId: predictions[0].place_id,
          });

          setCheckingYotiAddress(false);
          return predictions;
        }
        throw new Error(
          `The response from the Address API contained an unexpected value for predictions: ${predictions}`
        );
      })
      .catch((e) => {
        console.error(e);
        setCheckingYotiAddress(false);
        return null;
      });
  }, [accessToken, handleStoreUserAddress, initialAddress, setCheckingYotiAddress]);


  useEffect(() => {
    if (address && (address as unknown as any).placesId) return;

    setCheckingYotiAddress(true);

    setInitialAddressFromGoogleMaps();
  }, [address, setCheckingYotiAddress, setInitialAddressFromGoogleMaps]);

  const getAddresses = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (!searchTerm) return;
        getAddressPredictions(accessToken as string, searchTerm)
          .then((response) => {
            return response.data;
          })
          .then((response) => {
            const predictions = response.data.predictions;
            if (Array.isArray(predictions) && predictions.length >= 0) {
              return predictions;
            }
            throw new Error(
              `The response from the Address API contained an unexpected value for predictions: ${predictions}`
            );
          })
          .then((predictions) => {
            setLoading(false);
            setOptions(predictions);
          })
          .catch((response) => {
            if (!Object.getPrototypeOf(response).__CANCEL__) {
              if (response.status === 400 || response.status === 404)
                setError("Address not found");
              setLoading(false);
            }
          });
      }, 1000),
    [accessToken]
  );
  const handleChange = (value: string) => {
    if (!value) return;
    setLoading(true);
    getAddresses(value);
  };

  if (!hideSearch)
    return (
      <>
        <Autocomplete
          className={classes.textField}
          id="address-select"
          filterOptions={(x) => x}
          filterSelectedOptions
          disabled={disabled}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onChange={async (_e: any, value: AutoCompletePrediction | null) => {
            if (!value) return;
            try {
              /*const result = await getAddressDetails(value.place_id as string);
              if (!result) return;

              const formattedAddress = result.data.result.formatted_address;

              const placeId = value.place_id;

              */

              const usersAddress: UserProfileAddress = {
                formattedAddress: value.description,
                placesId: value.place_id as unknown as string,
              } as unknown as any;

              onSelect(usersAddress);
            } catch (e) {
              console.error(e);
            }
          }}
          getOptionLabel={(option) =>
            (option.description?.length as unknown as number) > 0
              ? (option.description as string)
              : ""
          }
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete="new-password"
              label="Address Search"
              onChange={(e) => handleChange(e.target.value)}
              variant="outlined"
              error={!!error}
              helperText={error}
              inputProps={{
                ...params.inputProps,
                autoComplete: "off",
                form: {
                  autocomplete: 'off',
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </>
    );
  else return <></>;
};
