import React, { useState, FC, useEffect } from "react";
import { LOGIN_TYPE, USER_EMAIL_KEY, USER_PHONE_KEY } from "../config/localStorage";
import { createGenericContext } from "../context";
import { getItem } from "../helpers/localstorage";

type UserContextType = {
  address: string;
  email: string;
  phone: string;
  loginType: string;
  setAddress: (value: string) => void;
  setEmail: (value: string) => void;
  setPhone: (value: string) => void;
  setLoginType: (value: string) => void;
};

const [useUserContext, UserContextProvider] =
  createGenericContext<UserContextType>();

const UserProvider: FC = ({ children }) => {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [loginType, setLoginType] = useState("");

  useEffect(() => {
    const storedPhone = getItem(USER_PHONE_KEY);
    const storedEmail = getItem(USER_EMAIL_KEY);
    const storedLoginType = getItem(LOGIN_TYPE);
    if (storedPhone) setPhone(storedPhone);
    if (storedEmail) setEmail(storedEmail);
    if (storedLoginType) setLoginType(storedLoginType);
  }, []);

  // useEffect(() => {
  //   if (phone) setItem(USER_PHONE_KEY, phone);
  //   if (email) setItem(USER_EMAIL_KEY, email);
  // }, [phone, email]);

  return (
    <UserContextProvider
      value={{
        address,
        email,
        phone,
        loginType,
        setAddress,
        setEmail,
        setPhone,
        setLoginType,
      }}
    >
      {children}
    </UserContextProvider>
  );
};

export { useUserContext, UserProvider };
