import React, {
  FormEvent,
  useCallback,
  useEffect,
  useState,
  useMemo,
  useRef,
  FC,
} from "react";
import { useHistory } from "react-router-dom";

import {
  Paper,
  Avatar,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  GridList,
  GridListTile,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  TextField,
  useTheme,
  ListItemSecondaryAction,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Home } from "@material-ui/icons";
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import dayjs from "dayjs";

import {
  getYotiData,
  getYotiFiles,
  updateUserProfile,
} from "../../../lib/api/api";
import { useAuthContext } from "../../../lib/auth";
import { SIGNATURE, YOTI_ERROR } from "../../../lib/config/routes";
import { camelCaseToNormal } from "../../../lib/helpers";
import { UserAddressHistory, YotiProfile } from "../../../lib/user/types";
import { useStyles } from "../styles";
import { AddressHistory } from "./AddressHistory";
import { getItem } from "../../../lib/helpers/localstorage";

import { YOTI_SESSION_ID } from "../../../lib/config";

import { debounce, isDateValid } from "../../../lib/helpers";
import { SignUpContainer } from "../../../templates/SignUpTemplate/SignUpContainer";

const minMax = require("dayjs/plugin/minMax");

dayjs.extend(minMax);

export const ConfirmIdentity: FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { accessToken } = useAuthContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [checkingYotiAddress, setCheckingYotiAddress] =
    useState<boolean>(false);
  const [sessionId, setSessionId] = useState<string>("");

  const [profile, setUserProfile] = useState<YotiProfile | null>(null);
  const [files, setFiles] = useState<string[]>([]);
  const [fetchingProfile, setFetchingProfile] = useState<boolean>(false);

  const [fetchingDocuments, setFetchingDocuments] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState(false);

  const [inputFieldsErrorCodes, setInputFieldsErrorCodes] = useState<
    Array<object>
  >([]);

  const submitBtnRef = React.useRef<HTMLButtonElement>(null);
  const [buttonInView, setButtonInView] = useState<boolean>(false);

  // check if submitBtnRef is outside of viewport
  useEffect(() => {
    // Check if button is in viewport
    const checkIfInView = () => {
      if (submitBtnRef.current === null) return;
      const rect = submitBtnRef.current?.getBoundingClientRect();
      const isInView = rect?.top >= 0 && rect?.left >= 0 && rect?.bottom <= window.innerHeight && rect?.right <= window.innerWidth;
      if (isInView !== buttonInView) setButtonInView(isInView);
    }

    // Call the function initially
    checkIfInView();

    // Call the function whenever the window is scrolled or resized
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);

    // Clean up function
    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    }
  }, [buttonInView]);

  const profileTimeout = useRef<any>(null);
  const documentTimeout = useRef<any>(null);

  function toPascalCase(str: string): string {
    // Split the string into words based on whitespace
    const words = str.split(/\s+/);

    // Convert each word to pascal case
    const pascalWords = words.map((word) => {
      const lower = word.toLowerCase();
      return lower.charAt(0).toUpperCase() + lower.slice(1);
    });

    // Join the pascal case words back into a single string
    return pascalWords.join(" ");
  }

  const onChangeProfileFieldInput = useMemo(
    () =>
      debounce((event: any, key: string) => {
        const target: any = event.target;

        if (
          camelCaseToNormal(key).includes("date") ||
          camelCaseToNormal(key).includes("Date")
        ) {
          if (!isDateValid(target.value) || !target.value) {
            event.target.value = "";
            setInputFieldsErrorCodes([
              ...inputFieldsErrorCodes,
              { [key]: "Please enter date in format YYYY-MM-DD" },
            ]);
            return;
          } else {
            const newInputFieldsErrorCodes = inputFieldsErrorCodes;

            newInputFieldsErrorCodes.forEach((code: object, index: number) => {
              if (code.hasOwnProperty(key)) {
                newInputFieldsErrorCodes.splice(index, 1);
              }
            });

            setInputFieldsErrorCodes(newInputFieldsErrorCodes);
          }
        } else {
          if (event.target.value === "") {
            setInputFieldsErrorCodes([
              ...inputFieldsErrorCodes,
              { [key]: "This field is required." },
            ]);
          } else {
            const newInputFieldsErrorCodes = inputFieldsErrorCodes;

            newInputFieldsErrorCodes.forEach((code: object, index: number) => {
              if (code.hasOwnProperty(key)) {
                newInputFieldsErrorCodes.splice(index, 1);
              }
            });

            setInputFieldsErrorCodes(newInputFieldsErrorCodes);
          }
        }

        setUserProfile({
          ...(profile as unknown as YotiProfile),
          [key]: event.target.value,
        });
      }, 2000),
    [inputFieldsErrorCodes, profile]
  );

  const handleFetchYotiProfile = useCallback(() => {
    if (!accessToken) return;
    setFetchingProfile(true);
    return getYotiData(accessToken)
      .then((res) => res.data.data)
      .then((data) => {
        const { profile, yotiStatus } = data;
        if (yotiStatus === 'WAITING_RESPONSE') {
          profileTimeout.current = setTimeout(() => handleFetchYotiProfile(), 10000);
          return;
        } else if (yotiStatus === 'FAIL') {
          history.push(YOTI_ERROR);
        }
        Object.keys(profile).forEach((key) => {
          if (
            (key === "fullName" ||
              key === "dateOfBirth" ||
              key === "placeOfBirth" ||
              key === "expirationDate" ||
              key === "dateOfIssue" ||
              key === "issuingAuthority" ||
              key === "documentNumber") &&
            !(profile as unknown as any)[key]
          ) {
            setInputFieldsErrorCodes([
              ...inputFieldsErrorCodes,
              {
                [key]:
                  "This field hasn't been retrieved and it is required. Please do enter it.",
              },
            ]);
          }
        });
        if (profile.fullName) profile.fullName = toPascalCase(profile.fullName);
        if (profile.placeOfBirth) profile.placeOfBirth = toPascalCase(profile.placeOfBirth);
        if (!profile.namePrefix) profile.namePrefix = '';
        if (!profile.nameSuffix) profile.nameSuffix = '';
        setUserProfile({
          ...profile,
        });
        setFetchingProfile(false);
      })
      .catch((e) => {
        console.error(e);
        setFetchingProfile(false);
      });
  }, [accessToken, history, inputFieldsErrorCodes]);

  useEffect(() => {
    handleFetchYotiProfile();

    return () => { profileTimeout.current && clearTimeout(profileTimeout.current) };
  }, [handleFetchYotiProfile]);

  const fetchYotiDocuments = useCallback(() => {
    if (!accessToken) return;
    setFetchingDocuments(true);
    getYotiFiles(accessToken).then((result) => {
      const data = result.data.data;
      setFiles(data.result);
      setFetchingDocuments(false);
    }).catch((e) => {
      console.error(e);
      documentTimeout.current && clearTimeout(documentTimeout.current);
      documentTimeout.current = setTimeout(() => fetchYotiDocuments(), 5000);
    });
  }, [accessToken]);


  useEffect(() => {
    fetchYotiDocuments();
  }, [accessToken]);

  useEffect(() => {
    if (!sessionId) {
      setSessionId(getItem(YOTI_SESSION_ID));
    }
  }, [sessionId]);

  const handleUpdateProfileAddress = (updatedAddress: UserAddressHistory) => {
    if (!profile) return;

    const newProfile = {
      ...profile,
      addresses: [updatedAddress],
    };

    setUserProfile({
      ...newProfile,
      addresses: formatAddressesDates(newProfile),
    });
  };

  const handleDeleteAddress = (id: string) => {
    if (!profile) return;

    setUserProfile({
      ...profile,
      addresses: profile.addresses.filter((a) => a.placesId !== id),
    });
  };

  const formatAddressesDates = (profile: YotiProfile) => {
    return profile.addresses.map((address) => {
      return {
        ...address,
      };
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!accessToken || !profile) return;

    setLoading(true);
    updateUserProfile(accessToken, profile)
      .then((response) => {
        setLoading(false);
        history.push(SIGNATURE);
      })
      .catch((error) => {
        console.error(error.data);
        setLoading(false);
      });
  };
  if (fetchingDocuments || fetchingProfile || checkingYotiAddress)
    return (
      <SignUpContainer>
        <Grid container>
          <Grid item container xs={12} justify="center" alignItems="center">
            <Typography>Please don't close this page. We are fetching your document.</Typography>
            <Typography>This can take up to several minutes.</Typography>
          </Grid>
          <Grid item container xs={12} style={{ marginTop: theme.spacing(4) }} justify="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </Grid>
      </SignUpContainer>
    );

  if (!profile)
    return (
      <SignUpContainer>
        <Grid container>
          <Typography variant="h5" gutterBottom>
            Confirm your details
          </Typography>
          <Typography gutterBottom align="center">
            We were unable to get any details from your document. Please
            retry, by clicking the button below:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFetchYotiProfile()}
          >
            Retry
          </Button>
        </Grid>
      </SignUpContainer>
    );

  return (
    <>
      <SignUpContainer size="md">
        <Paper variant="outlined" className={classes.paper}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                Confirm your details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom align="center">
                We were able to retrieve these details from your document.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom align="center">
                Please confirm they are correct and add any missing information.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                style={{ textTransform: "uppercase", fontSize: "16px", color: theme.palette.text.secondary }}
                variant={"caption"}
              >
                DOCUMENT PHOTOS
              </Typography>
              <Divider style={{ marginBottom: theme.spacing(1), backgroundColor: theme.palette.primary.main }} />
            </Grid>
            <Grid item xs={12}>
              <GridList
                className={classes.gridList}
                cols={files.length > 1 ? 2 : 1}
                cellHeight={'auto'}
              >
                {files.map((url: string) => (
                  <GridListTile key={url}>
                    <CardMedia className={classes.media} image={url} />
                  </GridListTile>
                ))}
              </GridList>
              {!!profile && (
                <Grid container spacing={2} style={{ marginTop: theme.spacing(5) }}>
                  {
                    <>
                      <Grid item xs={12}>
                        <Typography
                          style={{ textTransform: "uppercase", fontSize: "16px", color: theme.palette.text.secondary }}
                          variant={"caption"}
                        >
                          Personal Details
                        </Typography>
                        <Divider style={{ marginBottom: theme.spacing(1), backgroundColor: theme.palette.primary.main }} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          error={
                            inputFieldsErrorCodes?.find((code) =>
                              code.hasOwnProperty("fullName")
                            )
                              ? true
                              : false
                          }
                          helperText={
                            inputFieldsErrorCodes?.find((code: any) =>
                              code.hasOwnProperty("fullName")
                            )
                              ? (
                                inputFieldsErrorCodes?.find((code: any) =>
                                  code.hasOwnProperty("fullName")
                                ) as any
                              )["fullName"]
                              : ""
                          }
                          required
                          id="outlined-required"
                          variant="outlined"
                          label={"Full Name"}
                          defaultValue={profile["fullName"]}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(event, "fullName");
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          id="outlined-required"
                          variant="outlined"
                          label={"Date Of Birth"}
                          defaultValue={profile["dateOfBirth"]}
                          type={"date"}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(event, "dateOfBirth");
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          error={
                            inputFieldsErrorCodes?.find((code) =>
                              code.hasOwnProperty("placeOfBirth")
                            )
                              ? true
                              : false
                          }
                          helperText={
                            inputFieldsErrorCodes?.find((code: any) =>
                              code.hasOwnProperty("placeOfBirth")
                            )
                              ? (
                                inputFieldsErrorCodes?.find((code: any) =>
                                  code.hasOwnProperty("placeOfBirth")
                                ) as any
                              )["placeOfBirth"]
                              : ""
                          }
                          id="outlined-required"
                          label={"Place Of Birth"}
                          defaultValue={profile["placeOfBirth"]}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(event, "placeOfBirth");
                          }}
                        />
                      </Grid>
                    </>
                  }
                </Grid>
              )}
              {!!profile && (
                <Grid container spacing={2} style={{ marginTop: theme.spacing(5) }}>
                  {
                    <>
                      <Grid item xs={12}>
                        <Typography
                          style={{ textTransform: "uppercase", fontSize: "16px", color: theme.palette.text.secondary }}
                          variant={"caption"}
                        >
                          Document Details
                        </Typography>
                        <Divider style={{ marginBottom: theme.spacing(1), backgroundColor: theme.palette.primary.main }} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          id="outlined-required"
                          variant="outlined"
                          label={"Expiration Date"}
                          defaultValue={profile["expirationDate"]}
                          type={"date"}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(
                              event,
                              "expirationDate"
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          id="outlined-required"
                          variant="outlined"
                          label={"Date Of Issue"}
                          defaultValue={profile["dateOfIssue"]}
                          type={"date"}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(event, "dateOfIssue");
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          error={
                            inputFieldsErrorCodes?.find((code) =>
                              code.hasOwnProperty("issuingAuthority")
                            )
                              ? true
                              : false
                          }
                          helperText={
                            inputFieldsErrorCodes?.find((code: any) =>
                              code.hasOwnProperty("issuingAuthority")
                            )
                              ? (
                                inputFieldsErrorCodes?.find((code: any) =>
                                  code.hasOwnProperty("issuingAuthority")
                                ) as any
                              )["issuingAuthority"]
                              : ""
                          }
                          id="outlined-required"
                          label={"Issuing Authority"}
                          defaultValue={profile["issuingAuthority"]}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(
                              event,
                              "issuingAuthority"
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          required
                          variant="outlined"
                          error={
                            inputFieldsErrorCodes?.find((code) =>
                              code.hasOwnProperty("documentNumber")
                            )
                              ? true
                              : false
                          }
                          helperText={
                            inputFieldsErrorCodes?.find((code: any) =>
                              code.hasOwnProperty("documentNumber")
                            )
                              ? (
                                inputFieldsErrorCodes?.find((code: any) =>
                                  code.hasOwnProperty("documentNumber")
                                ) as any
                              )["documentNumber"]
                              : ""
                          }
                          id="outlined-required"
                          label={"Document Number"}
                          defaultValue={profile["documentNumber"]}
                          onChange={(event: any) => {
                            event.persist();
                            onChangeProfileFieldInput(
                              event,
                              "documentNumber"
                            );
                          }}
                        />
                      </Grid>
                    </>
                  }
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginTop: theme.spacing(5) }}>
              <Typography
                style={{ textTransform: "uppercase", fontSize: "16px", color: theme.palette.text.secondary }}
                variant={"caption"}
              >
                Address
              </Typography>
              <Divider style={{ marginBottom: theme.spacing(1), backgroundColor: theme.palette.primary.main }} />
            </Grid>
            <Grid item xs={12}>
              {profile && profile.addresses && (
                <>
                  {
                    <AddressHistory
                      setCheckingYotiAddress={setCheckingYotiAddress}
                      updateAddressHistory={handleUpdateProfileAddress}
                      addresses={
                        profile.addresses as unknown as UserAddressHistory[]
                      }
                    />
                  }
                </>
              )}

              {profile &&
                profile.addresses &&
                profile.addresses.find(
                  (address) => address.placesId !== undefined
                ) && (
                  <List style={{ marginTop: 16, border: '1px solid #C4C4C4', borderRadius: theme.shape.borderRadius }}>
                    {profile.addresses.map((address, i) => (
                      <>
                        <div style={{ textAlign: "center", width: "100%" }}>
                          <ListItem
                            key={address.placesId}
                            divider={i !== profile.addresses.length - 1}
                          >
                            <ListItemAvatar>
                              {address.defaultAddress ? (
                                <Avatar>
                                  <Home />
                                </Avatar>
                              ) : (
                                <Avatar>
                                  <Home />
                                </Avatar>
                              )}
                            </ListItemAvatar>
                            <ListItemText
                              primary={address?.formattedAddress}
                              secondary={dayjs(address.addressFrom).format(
                                "MMMM YYYY"
                              )}
                            />
                            <ListItemSecondaryAction>
                              <IconButton
                                color="primary"
                                onClick={() =>
                                  handleDeleteAddress(address.placesId)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      </>
                    ))}
                  </List>
                )}
              <Box style={{ marginTop: theme.spacing(5) }}>
                <form onSubmit={handleSubmit} autoComplete="off">
                  {profile.addresses &&
                    profile.addresses.length !== 0 &&
                    inputFieldsErrorCodes.length === 0
                    ? (<FormControlLabel
                      control={
                        <Checkbox
                          required
                          checked={confirmed}
                          onChange={() => setConfirmed(!confirmed)}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={<Typography variant="button" align="center">
                        I confirm that all the above details are correct.
                      </Typography>}
                    />)
                    : <Typography variant="button" align="center">
                      We need all of the required fields above to be populated.
                    </Typography>
                  }
                  <Button
                    color="primary"
                    style={{ padding: theme.spacing(2, 1) }}
                    disabled={
                      loading ||
                      !confirmed ||
                      (profile.addresses && profile.addresses.length === 0) ||
                      inputFieldsErrorCodes.length !== 0
                    }
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    ref={submitBtnRef}
                  >
                    Submit
                  </Button>
                  {loading && <LinearProgress color="secondary" />}
                </form>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </SignUpContainer>
      {!buttonInView && <Box style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <ExpandMoreIcon fontSize="large" />
      </Box>}
    </>
  );
};