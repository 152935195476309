import React, { useEffect } from 'react';
import {
  Box,
  Paper,
  makeStyles,
  Button,
  Typography,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { IDENTITY } from '../../../lib/config/routes';
import { getYotiresults } from '../../../lib/api';
import { useAuthContext } from '../../../lib/auth';
import { SignUpContainer } from '../../../templates/SignUpTemplate/SignUpContainer';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  button: {
    padding: theme.spacing(2, 1),
  }
}));

export const YotiError = () => {
  const classes = useStyles();
  const history = useHistory();
  const { accessToken } = useAuthContext();

  useEffect(() => {
    if (accessToken)
      getYotiresults(accessToken)
        .then((res) => res.data.data)
        .catch((err) => console.log(err));
  }, [accessToken]);

  return (
    <SignUpContainer>
      <Paper className={classes.paper}>
        <Box display="flex" justifyContent="center" marginTop={4}>
          <Typography>Oh dear, looks like we couldn't verify your ID. </Typography>
        </Box>
        <Box display="flex" justifyContent="center" marginTop={1}>
          <Typography>This happens sometimes, let's try it again.</Typography>
        </Box>
        <Box display="flex" justifyContent="center" marginTop={4}>
          <Button
            color="primary"
            size="large"
            fullWidth
            className={classes.button}
            variant="contained"
            onClick={() => history.push(IDENTITY)}
          >
            TRY AGAIN
          </Button>
        </Box>
      </Paper>
    </SignUpContainer>
  );
};
