import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  CircularProgress,
  Container,
  makeStyles,
  Paper,
  Snackbar,
  Zoom,
} from "@material-ui/core";

import { Alert } from "../../../components/Feedback/Alert";
import { getYotiUrl } from "../../../lib/api/api";
import { useAuthContext } from "../../../lib/auth";
import { getParameterByName } from "../../../lib/helpers";
import { setItem } from "../../../lib/helpers/localstorage";
import { YOTI_SESSION_ID } from "../../../lib/config";
import { SignUpContainer } from "../../../templates/SignUpTemplate/SignUpContainer";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100svh - 145px)",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const IdentityForm = (props: any) => {
  const classes = useStyles();
  const history = useHistory();
  const [url, setUrl] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { accessToken } = useAuthContext();
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const body = document.querySelector("body");
    if (!body) return;

    body.className = "noScroll";

    return () => {
      body.className = "";
    };
  });

  useEffect(() => {
    if (!url) {
      getYotiUrl(accessToken || "")
        .then((res) => {
          setUrl(res.data.data.url);
          if (process.env.REACT_APP_ENVIRONMENT === "local") {
            setItem(
              YOTI_SESSION_ID,
              getParameterByName("sessionID", res.data.data.url) as string
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          const errorResponseBody = JSON.parse(error.request.response);
          setError(errorResponseBody.error.message);
        });
    }
  }, [url, history, accessToken]);

  return (
    <SignUpContainer image={null} title="" size={false}>
      <Zoom in>
        <Container maxWidth="lg" className={classes.container}>
          <Snackbar open={!!error} autoHideDuration={6000}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
          {!loading ? (
            <iframe
              width="100%"
              height="100%"
              style={{ border: "none", borderRadius: "8px" }}
              src={url}
              title="yoti-id-verification"
              allow="camera"
            />
          ) : (
            <Paper style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
              <CircularProgress />
            </Paper>
          )}
        </Container>
      </Zoom>
    </SignUpContainer>
  );
};
