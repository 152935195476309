import React, { useEffect, useRef, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  makeStyles,
  Box,
  Collapse,
  LinearProgress,
  InputAdornment,
  IconButton,
  Paper,
  Divider,
  Typography,
} from "@material-ui/core";

import {
  ACCESS_TOKEN_KEY,
  CHECK,
  USER_EMAIL_KEY,
  USER_PHONE_KEY,
} from "../../../lib/config";
import { confirmSignUp, signUp, resendVerificationCode } from "../../../lib/api";
import { useAuthContext } from "../../../lib/auth";
import {
  isValidEmail,
  isValidPhoneNumber,
  parseUKPhoneNumber,
} from "../../../lib/helpers";
import { useUserContext } from "../../../lib/user";
import EditIcon from '@material-ui/icons/Edit';
import { SignUpContainer } from "../../../templates/SignUpTemplate/SignUpContainer";
import { HelperText } from "../../SignIn/HelperText";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import OTPInput from "../../../components/OTPInput";
import { Slogan } from "../../../components/Slogan";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  or: {
    display: "block",
    color: "rgba(0, 0, 0, 0.32)",
    letterSpacing: "-1px",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fafafa",
    padding: 2,
  },
  logo: {
    margin: "0 auto",
    width: "auto",
    height: 128,
    padding: 24,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    padding: theme.spacing(2, 1),
  },
  signInButton: {
    textDecoration: 'underline',
    fontWeight: 600,
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  message: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  resendButton: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  otpQuestion: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

export const LoginType = {
  PHONE: 'phone',
  EMAIL: 'email',
}

interface FormError {
  message: string;
  code: string;
}

const noError: FormError = {
  message: '',
  code: '',
};

export const AddPhoneNumber = () => {
  const classes = useStyles();
  const history = useHistory();
  const queryParameters = new URLSearchParams(window.location.search)
  const { setAccessToken, setSessionId, sessionId } = useAuthContext();
  const { setEmail, setPhone } = useUserContext();

  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState<FormError>(noError);
  const [codeError, setCodeError] = useState<FormError>(noError);
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const [foundInLocalStorage, setFoundInLocalStorage] = useState(false);
  const [verificationNeeded, setVerificationNeeded] = useState(false);

  const inputField = useRef<HTMLInputElement>(null);

  const email = useRef("");
  const phone = useRef("");
  const loginType = useRef(LoginType.EMAIL);

  useEffect(() => {
    if (queryParameters.get('email')) {
      setUserName(queryParameters.get('email') ?? '');
      loginType.current = LoginType.EMAIL;
      setFoundInLocalStorage(true);
    }
    if (queryParameters.get('phone')) {
      setUserName(queryParameters.get('phone') ?? '');
      loginType.current = LoginType.PHONE;
      setFoundInLocalStorage(true);
    }
    if (queryParameters.get('confirmUsername')) {
      setUserName(queryParameters.get('confirmUsername') ?? '');
      loginType.current = LoginType.EMAIL;
      setVerificationNeeded(true);
    }
  }, []);

  useEffect(() => {
    if (!open) {
      if (inputField && inputField.current) {
        inputField.current.focus();
        inputField.current.select();
      }
    }
  }, [open])

  const handleEditUsername = () => {
    setOpen(false);
  };

  const redirectToLogin = () => {
    if (phone && (loginType.current === LoginType.PHONE)) {
      history.push('/sign-in?phone=' + userName);
    } else if (email && loginType.current === LoginType.EMAIL) {
      history.push('/sign-in?email=' + userName);
    }
  };


  const handleResendCode = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    setLoading(true);
    if (!isValidEmail(userName) && !isValidPhoneNumber(userName)) {
      setUserNameError({ message: "Invalid Email or Phone Number.", code: "INVALID_FORMAT" });
      setLoading(false);
      return;
    }
    if (isValidEmail(userName)) {
      email.current = userName;
      loginType.current = LoginType.EMAIL;
    }
    if (isValidPhoneNumber(userName)) {
      phone.current = parseUKPhoneNumber(userName);
      loginType.current = LoginType.PHONE;
    }
    resendVerificationCode({ email: email.current, phone: phone.current })
      .then((response) => { setSessionId(response.data.data.id) })
      .then(() => {
        setUserNameError(noError);
        setOpen(true);
      })
      .catch((error) => {
        const errorResponseBody = JSON.parse(error.request.response);
        switch (errorResponseBody.error.code) {
          case "USER_ALREADY_EXISTS":
            setUserNameError({ message: `User with this ${loginType.current === LoginType.PHONE ? 'phone number' : 'email'} already exists.`, code: "USER_ALREADY_EXISTS" });
            break;
          case "LimitExceededException":
            setUserNameError({ message: 'You have exceeded the limit of sending verification code. Please try again later.', code: "LimitExceededException" });
            break;
          default:
            setUserNameError({ message: 'Something went wrong. Please try again.', code: "UNKNOWN_ERROR" });
            break;
        }
      })
      .finally(() => setLoading(false));
  }, [userName, setSessionId]);

  useEffect(() => {
    if (verificationNeeded) {
      handleResendCode();
      setVerificationNeeded(false);
    }
  }, [verificationNeeded]);

  const resolveOtpButton = () => {
    return {
      actionFn: resendCode,
      buttonText: 'Resend code.',
    }
  }

  const resolveSignInButton = () => {
    return {
      actionFn: redirectToLogin,
      buttonText: 'Click here to sign in.',
    }
  }


  const handleUserNameSubmit = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
    e && e.preventDefault();
    setLoading(true);
    if (!isValidEmail(userName) && !isValidPhoneNumber(userName)) {
      setUserNameError({ message: "Invalid Email or Phone Number.", code: "INVALID_FORMAT" });
      setLoading(false);
      return;
    }
    if (isValidEmail(userName)) {
      email.current = userName;
      loginType.current = LoginType.EMAIL;
    }
    if (isValidPhoneNumber(userName)) {
      phone.current = parseUKPhoneNumber(userName);
      loginType.current = LoginType.PHONE;
    }
    signUp({ email: email.current, phone: phone.current })
      .then((response) => setSessionId(response.data.data.id))
      .then(() => {
        setUserNameError(noError);
        setOpen(true);
      })
      .catch((error) => {
        const errorResponseBody = JSON.parse(error.request.response);
        switch (errorResponseBody.error.code) {
          case "USER_ALREADY_EXISTS":
            setUserNameError({ message: `User with this ${loginType.current === LoginType.PHONE ? 'phone number' : 'email'} already exists.`, code: "USER_ALREADY_EXISTS" });
            break;

          default:
            setUserNameError({ message: 'Something went wrong. Please try again.', code: "UNKNOWN_ERROR" });
            break;
        }
      })
      .finally(() => setLoading(false));
  }, [userName, setSessionId]);

  const handleCodeSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    confirmSignUp(sessionId, code)
      .then((response) => {
        const token = response.data.data.AccessToken ?? null;
        setLoading(false);
        if (token) {
          setAccessToken(token);
          localStorage.setItem(ACCESS_TOKEN_KEY, token);
          if (isValidEmail(userName)) {
            setEmail(userName);
            localStorage.setItem(USER_EMAIL_KEY, userName);
          } else {
            setPhone(userName);
            localStorage.setItem(USER_PHONE_KEY, userName);
          }
          history.push(CHECK);
        } else {
          setLoading(false);
          setCodeError({ message: "Incorrect Code.", code: "INCORRECT_CODE" });
        }
      })
      .catch((error) => {
        const errorResponseBody = JSON.parse(error.request.response);
        console.error(errorResponseBody);
        switch (errorResponseBody.error.code) {
          case "CodeMismatchException":
            setCodeError({ message: "Incorrect code entered.", code: "INCORRECT_CODE" });
            break;
          case "ExpiredCodeException":
            setCodeError({ message: "Code has expired.", code: "EXPIRED_CODE" });
            break;
          case "LimitExceededException":
            setCodeError({ message: 'You have exceeded the limit of sending verification code. Please try again later.', code: "LimitExceededException" });
            break;
          default:
            setCodeError({ message: errorResponseBody.error.message, code: "UNKNOWN_ERROR" });
            break;
        }
        setLoading(false);
      });
  };

  const resendCode = () => {
    setCodeError(noError);
    setCode("");
    handleUserNameSubmit();
  };


  useEffect(() => {
    if (foundInLocalStorage)
      handleUserNameSubmit();
  }, [foundInLocalStorage, handleUserNameSubmit, userName])

  return (
    <SignUpContainer image={null} title="">
      <Paper className={classes.paper}>
        <Slogan text="We support your rights as an athlete" />
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) =>
            !open ? handleUserNameSubmit(e) : handleCodeSubmit(e)
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={!open}>
                <TextField
                  error={!!userNameError.message}
                  fullWidth
                  inputRef={inputField}
                  InputProps={{ endAdornment: open && <InputAdornment position="end"><IconButton disabled={loading} onClick={handleEditUsername}><EditIcon color={loading ? "disabled" : "primary"} /></IconButton></InputAdornment> }}
                  disabled={open}
                  FormHelperTextProps={{
                    style: {
                      marginRight: 0,
                      marginLeft: 0
                    },
                  }}
                  helperText={<HelperText
                    errorMessage={userNameError.message || ''}
                    isError={!!userNameError.message}
                    button={userNameError.code === 'USER_ALREADY_EXISTS' ? resolveSignInButton() : undefined} />
                  }
                  id="username"
                  label="Email or Phone Number"
                  name="username"
                  onChange={(e) => {
                    if (!!userNameError.message) setUserNameError(noError);
                    if (foundInLocalStorage) setFoundInLocalStorage(false);
                    setUserName(e.target.value);
                  }}
                  required
                  type="text"
                  value={userName}
                  variant="outlined"
                />
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={open}>
                <>
                  {/* <TextField
                  error={!!otpError.message}
                  id="standard-basic"
                  label="One Time Code"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    autoCapitalize: 'none',
                    autoCorrect: 'off',
                    inputMode: "numeric"
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginRight: 0,
                    },
                  }}
                  helperText={<HelperText
                    errorMessage={otpError.message}
                    isError={!!otpError.message}
                    // helperText={`Enter the code from the ${email.current ? 'email' : 'SMS'} we sent you.`}
                    button={resolveOtpButton()} />
                  }
                  onChange={(e) => {
                    if (!!otpError.message) setOTPError(defaultError);
                    setCode(e.target.value);
                  }}
                  value={code}
                /> */}
                  <Box>
                    {email.current ? <MailOutlineIcon className={classes.title} style={{ fontSize: '6em' }} />
                      : <SmartphoneIcon className={classes.title} style={{ fontSize: '6em' }} />}
                    <Typography align="center" className={classes.title}>Verification code</Typography>
                  </Box>
                  <Divider />
                  {email.current ? <Typography align="left" className={classes.message}>
                    Please enter 6-digit code that was sent to {email.current || 'your email'}.
                  </Typography> : <Typography align="left" className={classes.message}>
                    Please enter 6-digit code that was sent to {phone.current || 'your phone via SMS'}.
                    {/* <Button variant="outlined" className={classes.resendButton} onClick={resendCode}>Resend code.</Button> */}
                  </Typography>}

                  <OTPInput
                    autoFocus
                    length={6}
                    inputStyle={codeError.message ? { border: '1px solid red' } : {}}
                    style={{ marginTop: 32, marginBottom: 32 }}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={(e) => {
                      if (!!codeError.message) setCodeError(noError);
                      setCode(e);
                      console.log(e);
                    }}
                  />
                  <HelperText
                    errorMessage={codeError.message}
                    isError={!!codeError.message} />
                  <Typography align="left" className={classes.otpQuestion}>
                    Did not receive the verification code? <span className={classes.resendButton} onClick={resendCode}>Resend code.</span>
                  </Typography>
                </>
              </Collapse>
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Button
              className={classes.button}
              disabled={loading}
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              {!!userNameError.message
                ? "Retry"
                : !open
                  ? "Submit"
                  : "Submit Code"}
            </Button>
            {loading && <LinearProgress color="secondary" />}
          </Box>
        </form>
      </Paper>
    </SignUpContainer>
  );
};
