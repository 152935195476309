import React, { FC, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon, Divider, Badge } from "@material-ui/core";
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AccountBox } from '@material-ui/icons';

import { PORTAL, CASES, SETTINGS, COMPANIES } from "../../lib/config";

type Props = {
  signOut: () => void;
  notifications: any;
};

export const MainNavigation: FC<Props> = ({ signOut, notifications }) => {
  const history = useHistory();
  const [companiesNotifications, setCompaniesNotifications] = useState(notifications[COMPANIES]);

  useEffect(() => {
    setCompaniesNotifications(notifications[COMPANIES]);
  }, [notifications]);

  return (
    <>
      <List>
        <ListItem selected={history.location.pathname === PORTAL} button component={RouterLink} to={PORTAL}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem selected={history.location.pathname === CASES} button component={RouterLink} to={CASES}>
          <ListItemIcon>
            <BusinessCenterIcon />
          </ListItemIcon>
          <ListItemText primary="Cases" />
        </ListItem>
        <ListItem selected={history.location.pathname === COMPANIES} button component={RouterLink} to={COMPANIES}>
          <ListItemIcon>
            {companiesNotifications ? <Badge badgeContent='!' color="error"><BusinessIcon /></Badge> : <BusinessIcon />}
          </ListItemIcon>
          <ListItemText primary="Companies" />
        </ListItem>
        <ListItem selected={history.location.pathname === SETTINGS} button component={RouterLink} to={SETTINGS}>
          <ListItemIcon>
            <AccountBox />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItem>
        <Divider />
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sign Out" />
        </ListItem>
      </List>
    </>
  );

}
