import React, { forwardRef, ForwardedRef } from 'react';
import { Stepper, Step, StepLabel, makeStyles, Paper, StepConnector, StepIconProps, withStyles, } from '@material-ui/core';
import { StepperItem } from '../../lib/hooks/useClaimantSignupJourney';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';



const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    zIndex: theme.zIndex.appBar,
    backgroundColor: 'transparent',
  },
  stepper: {
    backgroundColor: 'transparent',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
    zIndex: theme.zIndex.appBar,
  },
  message: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 700,
    color: 'whitesmoke',
    marginTop: theme.spacing(1),
  },
}));


const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.success.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.success.main,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: 'whitesmoke',
  },
  upcoming: {
    zIndex: 1,
    fontSize: 24,
  },
  circle: {
    width: 8,
    height: 8,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 24,
  },
  current: {
    color: 'whitesmoke',
    zIndex: 1,
    fontSize: 24,
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CheckCircleIcon className={classes.completed} /> : active ? <FiberManualRecordIcon className={classes.current} /> : <div className={classes.circle} />}
    </div>
  );
}

function LastQontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      <EmojiFlagsIcon className={classes.upcoming} />
    </div>
  );
}

interface Props {
  children?: React.ReactNode;
  steps: StepperItem[];
  currentStepIndex: number;
  currentStep: StepperItem | null;
  showStepper: boolean;
}

const RouterStepper = forwardRef((props: Props, ref: ForwardedRef<HTMLDivElement>) => {
  const classes = useStyles();
  const { steps, currentStepIndex, showStepper } = props;

  return (
    <>
      {showStepper && <><Paper ref={ref} square className={classes.paper}>
        <Stepper
          connector={<QontoConnector />}
          alternativeLabel
          activeStep={currentStepIndex}
          className={classes.stepper}>
          {steps.map(({ label, completed }, index) => (
            <Step key={label} completed={completed}>
              <StepLabel StepIconComponent={index !== steps.length - 1 ? QontoStepIcon : LastQontoStepIcon} />
              {/* {currentStep?.label === label && <Typography align="center" className={classes.message} style={{ color: 'whitesmoke' }}>{label}</Typography>} */}
            </Step>
          ))}
        </Stepper>
        {/* <Typography align="center" className={classes.message} component="p" variant="button">
          {currentStep ? currentStep.label :
            <CircularProgress size={20} style={{ color: 'whitesmoke' }} />
          }
        </Typography> */}
      </Paper>
      </>}

    </>
  );
});

export default RouterStepper;