export const ACCESS_TOKEN_KEY = 'GSDT::CLAIMANT_ACCESS_TOKEN'
export const USER_DETAILS_KEY = 'GSDT::CLAIMANT_DETAILS'
export const USER_ADDRESS_KEY = 'GSDT::CLAIMANT_ADDRESS'
export const USER_EMAIL_KEY = 'GSDT::CLAIMANT_EMAIL'
export const USER_PHONE_KEY = 'GSDT::CLAIMANT_PHONE'
export const USER_PFA_KEY = 'GSDT::CLAIMANT_PFA'
export const YOTI_SESSION_ID = 'GSDT::YOTI_SESSION_ID'
export const YOTI_ENVELOPE_ID = 'GSDT::YOTI_ENVELOPE_ID'
export const YOTI_ENVELOPE_SIGNER_EMAIL = 'GSDT::YOTI_ENVELOPE_SIGNER_EMAIL'
export const DEV_YOTI_MOCK_PROFILE = 'GSDT::DEV_YOTI_MOCK_PROFILE';
export const LOGIN_TYPE = 'GSDT::LOGIN_TYPE';
export const USER = 'GSDT::USER';
