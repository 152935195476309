import React, { FC } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';

import { Skeleton } from '@material-ui/lab';
import _ from 'lodash';

const useStyles = makeStyles(() => ({
  table: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

type SkeletonColumn = {
  label: string;
};

type Props = {
  rows?: number;
  title?: string;
  columns?: SkeletonColumn[];
};

export const SkeletonTable: FC<Props> = ({ columns = [], rows = 5 }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.length > 0 ? (
              <>
                {columns.map((c, i) => (
                  <TableCell key={`${c.label}-${i}`}>{c.label}</TableCell>
                ))}
              </>
            ) : (
              <>
                {Array.from(Array(5).keys()).map((_c, i) => (
                  <TableCell key={i}>
                    <Skeleton
                      animation='wave'
                      height={6}
                      width={`${_.random(20, 60)}%`}
                      variant='rect'
                      style={{ marginBottom: 4 }}
                    />
                  </TableCell>
                ))}
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(Array(rows).keys()).map((i) => (
            <TableRow key={i}>
              {Array.from(Array(columns.length || 5).keys()).map((j) => (
                <TableCell key={`${i}-${j}`}>
                  <Skeleton
                    animation='wave'
                    height={8}
                    width={`${_.random(20, 100)}%`}
                    variant='rect'
                    style={{ marginBottom: 4 }}
                  />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
