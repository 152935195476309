import React, { useEffect } from 'react';
import {
  Paper,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { SECONDARY_CONTACT, YOTI_ERROR } from '../../../lib/config/routes';
import { SignUpContainer } from '../../../templates/SignUpTemplate/SignUpContainer';
import { updateYotiSession } from '../../../lib/api';
import { useAuthContext } from '../../../lib/auth';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  button: {
    padding: theme.spacing(2, 1),
  }
}));

export const YotiSuccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const { accessToken } = useAuthContext();

  useEffect(() => {
    history.push(SECONDARY_CONTACT);
  }, [history]);

  useEffect(() => {
    if (accessToken)
      updateYotiSession(accessToken)
        .then((res) => res.data.data)
        .then((data) => {
          history.push(SECONDARY_CONTACT);
        })
        .catch((err) => {
          if (err.response.data.error.code === 'INVALID_IDENTITY_VERIFICATION_REQUEST') {
            history.push(SECONDARY_CONTACT);
          } else {
            history.push(YOTI_ERROR);
          }
        });
  }, [accessToken, history]);

  return (
    <SignUpContainer>
      <Paper className={classes.paper}>
        <CircularProgress />
      </Paper>
    </SignUpContainer>
  );
};
