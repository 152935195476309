import React, { FC, useState } from "react";

import { Box, Button, CircularProgress } from "@material-ui/core";

import {
  UserAddressHistory,
  UserProfileAddress,
} from "../../../lib/user/types";
import { useStyles } from "../styles";
import { Address } from "./Address";

type Props = {
  addresses: UserAddressHistory[];
  updateAddressHistory: (address: UserAddressHistory) => void;
  setCheckingYotiAddress: (checkingYotiAddress: boolean) => void;
};

export const AddressHistory: FC<Props> = ({
  addresses,
  updateAddressHistory,
  setCheckingYotiAddress,
}) => {
  const classes = useStyles();

  const [selectedAddress, setSelectedAddress] =
    useState<UserProfileAddress | null>(null);

  const [isWaitingDetails, setIsWaitingDetails] = useState(false);

  const handleStoreUserAddress = (addressToStore: any) => {
    let newAddress: UserAddressHistory;

    if (addressToStore) {
      newAddress = {
        ...addressToStore,
        defaultAddress: true,
      } as unknown as UserAddressHistory;
    } else if (!selectedAddress) {
      return;
    } else {
      newAddress = {
        ...selectedAddress,
        defaultAddress: true,
      } as unknown as UserAddressHistory;
    }

    updateAddressHistory(newAddress);
  };

  return (
    <>
      <Address
        hideSearch={
          addresses &&
            addresses.length !== 0 &&
            addresses.find((address) => address.placesId !== undefined)
            ? true
            : false
        }
        handleStoreUserAddress={handleStoreUserAddress}
        initialAddress={
          addresses && addresses[0] ? addresses[0].formattedAddress : ""
        }
        address={addresses ? addresses[0] : null}
        setCheckingYotiAddress={setCheckingYotiAddress}
        disabled={false}
        onSelect={(val: UserProfileAddress) => setSelectedAddress(val)}
        setIsWaitingDetails={(isWaitingDetails: boolean) =>
          setIsWaitingDetails(isWaitingDetails)
        }
      />

      {(addresses &&
        addresses.find((address) => address.placesId === undefined)) ||
        addresses.length === 0 ? (
        <Box className={classes.buttons}>
          {
            <Button
              color="primary"
              onClick={() => {
                handleStoreUserAddress(null);
              }}
              variant="contained"
              disabled={!selectedAddress || isWaitingDetails}
            >
              {!isWaitingDetails ? (
                <span>Add address</span>
              ) : (
                <>
                  <CircularProgress color="inherit" size={15} />{" "}
                  <span style={{ marginLeft: "5px" }}>Add address</span>
                </>
              )}
            </Button>
          }
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
