import React, { FC, ReactNode } from "react";

import { Box, Container, Grow, Typography, makeStyles } from "@material-ui/core";
import logoImage from "../../assets/logo-image.png";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
  logo: {
    margin: "0 auto",
    marginBottom: theme.spacing(2),
    width: "30%",
    [theme.breakpoints.up('sm')]: {
      width: "40%",
    },
    height: 'auto',
  },
  message: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
  },
}));

type Props = {
  size?: false | "xs" | "sm" | "md" | "lg" | "xl";
  title?: string | ReactNode;
  image?: string | null;
};
export const SignUpContainer: FC<Props> = ({ children, size = "sm", title = "We support your rights as an athlete", image = logoImage }) => {
  const classes = useStyles();

  return (
    <Grow in>
      <Container className={classes.container} component="main" maxWidth={size}>
        {(image || title) && <Box>
          {image && <img
            className={classes.logo}
            src={image}
            alt="Tuxteam logo"
          />}
          {typeof title === "string" ? (<Typography className={classes.message} component="p" variant="button">
            {title}
          </Typography>) : title}
        </Box>}
        <div className={classes.paper}>
          {children}
        </div>
      </Container>
    </Grow>
  );
};
