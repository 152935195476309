import React, { useEffect, useState, ChangeEvent } from 'react';
import {
  Paper,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Slide,
  AppBar,
  Dialog,
  IconButton,
  Toolbar,
  DialogContent,
  useMediaQuery,
  Theme,
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from '@material-ui/icons/Notifications';
import InfoIcon from '@material-ui/icons/Info';

import { SkeletonTable } from '../../components/Loaders';
import { TableHeader } from '../../components/Table';
import { useAuthContext } from '../../lib/auth';
import { getCasesByClaimant } from '../../lib/api';
import { Thread } from './Thread';

const headCells = [
  {
    id: 'id',
    label: 'Cases',
  },
  {
    id: 'updates',
    label: 'Updates',
    align: 'center',
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 20,
    marginBottom: 20,
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(4),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(5),
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  iconButton: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MobileTransition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Portal = () => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const { accessToken } = useAuthContext();

  const [selected, setSelected] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [cases, setCases] = useState<Array<any>>([]);
  const [open, setOpen] = useState(false);
  const [selectedCase, setSelectedCase] = useState<any>(undefined);

  const handleClickOpen = (_selectedCase: any) => {
    setSelectedCase(_selectedCase);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedCase(undefined);
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    getCasesByClaimant(accessToken || '')
      .then((response: any) => {
        const data = response.data.data;
        setCases(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const errorResponseBody = JSON.parse(error.request.response);
        console.error(errorResponseBody);
        setLoading(false);
      });
  }, [accessToken]);

  const handleSelectAllClick = (event: ChangeEvent<HTMLElement>) => {
    const target: any = event.target;
    if (target.checked) {
      const newSelecteds = cases.map((c) => c.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    return;
  };

  if (loading) return <SkeletonTable columns={headCells} />;

  if (!cases || cases.length < 1)
    return (
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="table of cases">
          <TableHead>
            <TableCell>Cases</TableCell>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='center' component="th" scope="row" colSpan={3}>
                <InfoIcon fontSize='large' />
                <Typography variant='caption' component='p' style={{ textTransform: 'uppercase' }}>You have no active cases.</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );

  return (
    <TableContainer className={classes.table} component={Paper}>
      <Table aria-label="table of cases">
        <TableHeader
          headCells={headCells}
          onSelectAllClick={handleSelectAllClick}
          numSelected={selected.length}
          rowCount={cases.length}
        />
        <TableBody>
          {cases.map((c: any, index: number) => {
            return (
              <React.Fragment key={c.id}>
                <TableRow tabIndex={-1} key={c.id}>
                  <TableCell component="th" scope="row">
                    {c.claimant.profile?.fullName ?? 'Unknown'} vs{' '}
                    {c.defendant.companyName}
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton
                      className={classes.iconButton}
                      color="primary"
                      size="small"
                      onClick={() => handleClickOpen(c)}
                    >
                      <NotificationsIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
      {selectedCase && (
        <Dialog
          style={
            isSmallScreen
              ? { width: '100%', top: '25%' }
              : { width: '50%', left: '50%' }
          }
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={isSmallScreen ? MobileTransition : Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Updates
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Thread
              caseId={selectedCase.id}
              topicId={selectedCase.claimantTopic.id}
            />
          </DialogContent>
        </Dialog>
      )}
    </TableContainer>
  );
};
