import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    CircularProgress,
    Container,
    makeStyles,
    Paper,
    Snackbar,
    Zoom,
} from "@material-ui/core";

import { Alert } from "../../../components/Feedback/Alert";
import { getLetterOfIntentUrl } from "../../../lib/api/api";
import { useAuthContext } from "../../../lib/auth";
import { setItem } from "../../../lib/helpers/localstorage";
import {
    YOTI_ENVELOPE_ID,
    YOTI_ENVELOPE_SIGNER_EMAIL,
} from "../../../lib/config/localStorage";
import { PORTAL } from "../../../lib/config";

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flexDirection: "column",
        height: "calc(100lvh - 145px)",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export const Signature = () => {
    const classes = useStyles();
    const history = useHistory();
    const { accessToken } = useAuthContext();
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!accessToken) return;
        if (!url) {
            setLoading(true);
            getLetterOfIntentUrl(accessToken)
                .then((res) => {
                    setItem(YOTI_ENVELOPE_ID, res.data.data.envelopeId);
                    setItem(YOTI_ENVELOPE_SIGNER_EMAIL, res.data.data.email);
                    setUrl(res.data.data.url);
                    setLoading(false);
                })
                .catch((error) => {
                    const errorResponseBody = JSON.parse(
                        error.request.response
                    );
                    setError(errorResponseBody.error.message);
                    if (errorResponseBody.error.code === "AWAITING_COUNTERSIGNATURE") history.push(PORTAL);
                });
        }
    }, [url, accessToken, history]);

    return (
        <Zoom in>
            <Container maxWidth="lg" className={classes.container}>
                <Snackbar open={!!error} autoHideDuration={6000}>
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
                {!loading ? (
                    <iframe
                        width="100%"
                        height="100%"
                        style={{ border: "none", borderRadius: "8px" }}
                        src={url}
                        title="yoti-sign"
                    />
                ) : (
                    <Paper style={{ width: '100%', height: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                        <CircularProgress />
                    </Paper>
                )}
            </Container>
        </Zoom>
    );
};
