import React, { Children, FC, useCallback, useEffect } from "react";

import { Container, AppBar, Grow, makeStyles, Toolbar, CircularProgress, Button, Paper, Grid, useTheme } from "@material-ui/core";
import * as Routes from "../../lib/config";

import logo from "../../assets/logo-modified.svg";
import RouterStepper from "../../components/RouterStepper/RouterStepper";
import useClaimantSignupJourney from "../../lib/hooks/useClaimantSignupJourney";

import { ContactSupportDialog } from "./ContactSupportDialog";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
  logo: {
    margin: "0 auto",
    marginBottom: theme.spacing(2),
    width: "30%",
    [theme.breakpoints.up('sm')]: {
      width: "40%",
    },
    height: 'auto',
  },
  message: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    width: '30%',
  },
}));

const APPBAR_HEIGHT = 64;

export const SignUpTemplate: FC = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const stepperRef = React.createRef<HTMLDivElement>();
  const { loading, steps, currentStepIndex, currentStep, showStepper, nextButtonText, redirect } = useClaimantSignupJourney();
  const [containerHeight, setContainerHeight] = React.useState(0);


  const calculateHeight = useCallback(() => {
    const toolbarHeight = stepperRef.current?.clientHeight || APPBAR_HEIGHT;
    setContainerHeight(toolbarHeight);
  }, [stepperRef]);

  useEffect(() => {
    calculateHeight();
  }, [calculateHeight, stepperRef]);


  return (
    <>
      <AppBar position="fixed">
        {!showStepper && <Toolbar>
          <a href={Routes.HOME} style={{ textDecoration: 'none', color: 'inherit', margin: 'auto' }}>
            <img
              style={{ padding: '5px', margin: 'auto' }}
              src={logo}
              alt="Tuxteam logo"
              height="64px"
            />
          </a>
        </Toolbar>}
        <RouterStepper steps={steps} currentStepIndex={currentStepIndex} currentStep={currentStep} showStepper={showStepper} ref={stepperRef} />
      </AppBar >
      <Toolbar style={{ height: containerHeight }} />
      <Grow in>
        <Container className={classes.container} style={{ minHeight: `calc(100svh - ${2 * containerHeight}px)` }} component="main" maxWidth={false}>
          {loading ? <CircularProgress /> : <>
            {Children.toArray(children).map((child: any) => React.cloneElement(child, {}))}
          </>}
          {showStepper && <ContactSupportDialog />}
        </Container>
      </Grow>
      {/* Idea: Stepper at the top, controlls at the bottom */}
      {/* <Paper square style={{ height: APPBAR_HEIGHT, position: "fixed", bottom: 0, width: '100%', backgroundColor: theme.palette.primary.dark }}>
        <Grid container style={{ height: '100%', padding: '8px' }} alignContent="center" alignItems="center" justifyContent="center">
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button className={classes.button} variant="contained" onClick={redirect}>{nextButtonText}</Button>
          </Grid>
        </Grid>
      </Paper> */}
    </>
  );
};
