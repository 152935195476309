import React, { ChangeEvent, useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, TextField, DialogActions, Button, CircularProgress, Divider, Fab, makeStyles, Badge, Box, Chip } from '@material-ui/core';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { contactSupport } from '../../lib/api';
import { useAuthContext } from '../../lib/auth';
import { AttachFile, PostAdd } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    input: {
        display: 'none',
    },
    chip: {
        width: '100%',
    }
}));

export const ContactSupportDialog = () => {
    const classes = useStyles();
    const [submitting, setSubmitting] = useState(false);
    const { accessToken } = useAuthContext();
    const [supportMessage, setSupportMessage] = useState({ email: '', content: '' });
    const [open, setOpen] = React.useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [file, setFile] = useState<File>();
    const [previewURL, setPreviewURL] = useState<string>('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setMessageSent(false);
        setFile(undefined);
        setSupportMessage({ email: '', content: '' });
    };

    useEffect(() => {
        // create the preview
        if (!file) return;
        const objectUrl = URL.createObjectURL(file);
        setPreviewURL(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [file])

    // implement text field change
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSupportMessage({
            ...supportMessage,
            [e.target.name]: e.target.value,
        })
    }

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };


    const onSubmit = () => {
        // send email to support
        if (!accessToken) return;
        const formData = new FormData();
        formData.append("file", file as Blob);
        formData.append("email", supportMessage.email);
        formData.append("content", supportMessage.content);
        setSubmitting(true);
        contactSupport(accessToken, formData)
            .then(res => {
                setMessageSent(true);
                setSubmitting(false);
            }).catch(err => {
                setSubmitting(false);
                console.log(err);
            })
    }

    return (
        <>
            <Fab className={classes.fab} onClick={handleClickOpen}>
                <Badge variant="dot" badgeContent={supportMessage.content || supportMessage.email ? 1 : 0} color="error">
                    <ContactSupportIcon />
                </Badge>
            </Fab>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Contact support</DialogTitle>
                <Divider />
                {!messageSent ? (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                We're here to help! Please take a moment to describe the problem you're facing and send it to our support team. Your feedback is essential to us in providing the best possible assistance. Thank you!
                            </DialogContentText>
                            <TextField
                                id="email"
                                label="Your Email Address"
                                type="email"
                                required
                                fullWidth
                                onChange={handleChange}
                                name='email'
                                value={supportMessage.email}
                                variant='outlined'
                                style={{ marginBottom: '1em' }}
                            />
                            <TextField
                                id="description"
                                label="Describe the issue"
                                type="text"
                                multiline
                                minRows={7}
                                value={supportMessage.content}
                                name='content'
                                onChange={handleChange}
                                variant='outlined'
                                required
                                fullWidth
                            />
                            <Box marginTop={2}>
                                {!file ? (
                                    <>
                                        <input
                                            accept="image/*"
                                            className={classes.input}
                                            id="contained-button-file"
                                            multiple
                                            onChange={handleFileChange}
                                            type="file"
                                        />
                                        <label htmlFor="contained-button-file">
                                            <Button fullWidth variant="outlined" color="primary" component="span" endIcon={<AttachFile />}>
                                                Attach screenshot
                                            </Button>
                                        </label>
                                    </>
                                ) : (
                                    <>
                                        <Chip
                                            color="primary"
                                            icon={<PostAdd />}
                                            label={file.name}
                                            onDelete={() => setFile(undefined)}
                                            variant="outlined"
                                            className={classes.chip}
                                        />
                                        <img width={'100%'} src={previewURL} alt="preview" />
                                    </>
                                )}
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" variant="outlined">
                                Cancel
                            </Button>
                            <Button onClick={onSubmit} endIcon={submitting ? <CircularProgress size={'small'} /> : <></>} variant="contained" color="primary">
                                Send
                            </Button>
                        </DialogActions>
                    </>
                ) : (
                    <>
                        <DialogContent>
                            <DialogContentText>
                                Thank you for your message! We will get back to you as soon as possible.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" variant="outlined">
                                CLOSE
                            </Button>
                        </DialogActions>
                    </>
                )}

            </Dialog >
        </>
    );
}