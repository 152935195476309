import React, { useCallback, useEffect, useState } from "react";

import { Button, CircularProgress, Paper, Typography, makeStyles, useTheme } from "@material-ui/core";
import { getItem } from "../../../lib/helpers/localstorage";
import signatureImage from "../../../assets/signing-contract-svgrepo-com.svg";
import {
    YOTI_ENVELOPE_ID,
    YOTI_ENVELOPE_SIGNER_EMAIL,
} from "../../../lib/config/localStorage";
import { checkEnvelopeStatus } from "../../../lib/api";
import { SignUpContainer } from "../../../templates/SignUpTemplate/SignUpContainer";
import { PORTAL } from "../../../lib/config";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3, 4),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2, 2),
        },
    },
}));

export const SuccessfulSignature = () => {
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [esignatureObj, setEsignatureObj] = useState({
        envelope: "",
        email: "",
    });

    const [envelopeSigner, setEnvelopeSigner] = useState(undefined);

    useEffect(() => {
        const email = getItem(YOTI_ENVELOPE_SIGNER_EMAIL);
        const envelope = getItem(YOTI_ENVELOPE_ID);
        if (email && envelope) {
            setEsignatureObj({ email, envelope });
        }
    }, []);

    const fetchCheckEnvelopeStatus = useCallback(() => {
        if (!esignatureObj.email || !esignatureObj.envelope) return;
        setLoading(true);
        checkEnvelopeStatus(esignatureObj.email, esignatureObj.envelope)
            .then((res) => {
                setEnvelopeSigner(res.data.data);
                setLoading(false);
            })
            .catch((error: any) => {
                console.log(error);
                setLoading(false);
            });
    }, [esignatureObj.email, esignatureObj.envelope]);

    useEffect(() => {
        if (esignatureObj.envelope && esignatureObj.email)
            fetchCheckEnvelopeStatus();
        else setLoading(false);
    }, [esignatureObj, fetchCheckEnvelopeStatus]);


    const isClaimant = (signer: any) => {
        if (!signer) return false;
        return signer.role === 'Signer 1';
    };

    if (loading)
        return (
            <SignUpContainer title={''}>
                <CircularProgress />
            </SignUpContainer >
        );

    return (
        <SignUpContainer title={
            isClaimant(envelopeSigner) ?
                'Thanks for signing up!' : ''
        }>
            <Paper className={classes.paper}>
                <img width={'25%'} alt="signature-completed" style={{ padding: theme.spacing(1) }} src={signatureImage} />
                <Typography align="center" variant="button" component="p" style={{
                    width: '100%',
                    textAlign: 'center',
                    fontWeight: 700,
                }}>
                    Document signed successfully.
                </Typography>
                {!isClaimant(envelopeSigner) && <Typography align="center" variant="button" component="p" style={{
                    width: '100%',
                    textAlign: 'center',
                    color: theme.palette.text.secondary
                }}>
                    You can go ahead and close this page.
                </Typography>}
                {isClaimant(envelopeSigner) && <Button variant="contained" style={{ padding: theme.spacing(2, 1), marginTop: theme.spacing(3) }} onClick={() => history.push(PORTAL)} fullWidth color="primary">GO TO HOMEPAGE</Button>}
            </Paper>
        </SignUpContainer>
    );
};
