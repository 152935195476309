import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import {
    PORTAL,
    YOTI_ERROR,
    IDENTITY,
    SIGNATURE,
    SELECT_DEFENDANTS,
    CONFIRM_IDENTITY,
    SECONDARY_CONTACT,
    CHECK,
    YOTI_SUCCESS,
    COMPLETED_SIGNATURE,
} from "../config";
import { useAuthContext } from "../auth";
import {
    getClaimant,
} from '../api';

export type StepperItem = {
    label: string;
    description: string;
    completed: boolean;
    path: string;
}

export const initialSteps: StepperItem[] = [
    { label: 'Identification Process', description: 'Scan document and take picture.', completed: false, path: IDENTITY },
    { label: 'Alternative Contact Info', description: 'Add phone/email as alternative contact.', completed: false, path: SECONDARY_CONTACT },
    { label: 'Select Agencies', description: 'Exclude or include agencies.', completed: false, path: SELECT_DEFENDANTS },
    { label: 'Confirm Personal Information', description: 'Confirm personal/document details.', completed: false, path: CONFIRM_IDENTITY },
    { label: 'Signing Letter Of Intent', description: 'Sign a letter of intent.', completed: false, path: SIGNATURE },
];

const useClaimantSignupJourney = () => {
    const history = useHistory();
    const { accessToken } = useAuthContext();
    const [loading, setLoading] = useState(true);
    const [steps, setSteps] = useState<StepperItem[]>(initialSteps);
    const [currentStep, setCurrentStep] = useState<StepperItem | null>(null);
    const [currentStepIndex, setCurrentStepIndex] = useState(-1);
    const [claimant, setClaimant] = useState<any>(null);
    const [showStepper, setShowStepper] = useState(false);
    const [nextStep, setNextStep] = useState<StepperItem | null>(null);
    const [nextButtonText, setNextButtonText] = useState('Next');
    console.log(claimant, 'claimant');
    // should stepper be shown
    useEffect(() => {
        if (steps.find((step) => step.path === history.location.pathname)) {
            setShowStepper(true);
        }
    }, [history.location.pathname, steps]);


    const redirectIfNeeded = useCallback((currentStep) => {
        if (!currentStep) return;
        if (history.location.pathname === CHECK ||
            history.location.pathname === YOTI_SUCCESS ||
            history.location.pathname === YOTI_ERROR ||
            history.location.pathname === COMPLETED_SIGNATURE) return;
        if (currentStep.path !== history.location.pathname) {
            history.push(currentStep.path);
        }
    }, [history]);

    const resolveNextButtonText = useCallback((steps) => {
        if (!steps) return;
        const currentStepIndex = steps.findIndex((step: StepperItem) => !step.completed);
        setNextButtonText(steps[currentStepIndex + 1]?.label || 'Finish');
    }, []);

    // determine current step based on claimant
    useEffect(() => {
        if (!claimant) return;
        const _steps = [...initialSteps];
        if (claimant.identityStatus === 'PASS' || claimant.identityStatus === 'WAITING_RESPONSE') {
            _steps[0] = { ..._steps[0], completed: true };
        }
        if (claimant.emailStatus === "VERIFIED" && claimant.phoneNumberStatus === "VERIFIED") {
            _steps[1] = { ..._steps[1], completed: true };
        }
        if (claimant.claimantDefendants.length > 0) {
            _steps[2] = { ..._steps[2], completed: true };
        }
        if (claimant.profile && claimant.profile.status === 'COMPLETE') {
            _steps[3] = { ..._steps[3], completed: true };
        }
        if (claimant.contractStatus === 'SIGNED' || claimant.contractStatus === 'AWAITING_COUNTERSIGNATURE') {
            _steps[4] = { ..._steps[4], completed: true };
        }
        const currentStepIndex = _steps.findIndex((step) => !step.completed);
        setCurrentStepIndex(currentStepIndex);
        setCurrentStep(_steps[currentStepIndex]);
        setNextStep(_steps[currentStepIndex + 1]);
        resolveNextButtonText(_steps);
        setSteps(_steps);
        if (_steps.filter((step) => step.completed).length === _steps.length) history.push(PORTAL);
        redirectIfNeeded(_steps[currentStepIndex]);
    }, [claimant, history, redirectIfNeeded]);


    const fetchClaimant = useCallback(() => {
        if (accessToken) {
            setLoading(true);
            getClaimant(accessToken).then((data) => {
                const claimant = data.data.data;
                setClaimant(claimant);
                setLoading(false);
            }).catch((error) => {
                if (error.response && error.response.status === 401) {
                    history.push('/sign-in');
                }
            });
        }
    }, [accessToken, history]);


    const completedSteps = useCallback(() => {
        return steps.filter((step) => step.completed).length;
    }, [steps]);

    // figure out if there are any true values in the steps array
    const isUserResumingJourney = useCallback(() => {
        return steps.some((step) => step.completed);
    }, [steps]);


    useEffect(() => {
        if (showStepper || history.location.pathname === CHECK || history.location.pathname === YOTI_SUCCESS)
            fetchClaimant();
    }, [accessToken, fetchClaimant, history.location.pathname, showStepper]);

    useEffect(() => {
        // is location is not one of the steps set loading to false
        if (!steps.find((step) => step.path === history.location.pathname) && history.location.pathname !== CHECK) {
            setLoading(false);
        }
    }, [history.location.pathname, steps]);


    const redirect = useCallback(() => {
        if (!claimant) return;
        // ifidentity failed, redirect to error page
        if (claimant.identityStatus === 'FAIL' &&
            history.location.pathname !== IDENTITY &&
            history.location.pathname !== CHECK) {
            history.push(YOTI_ERROR);
            return;
        }

        // find first not completed step
        const firstNotCompletedStep = steps.find((step) => !step.completed);
        if (firstNotCompletedStep) {
            history.push(firstNotCompletedStep.path);
        } else {
            history.push(PORTAL);
        }
    }, [claimant, history, steps]);


    return {
        steps,
        currentStepIndex,
        currentStep,
        nextStep,
        loading,
        showStepper,
        nextButtonText,
        completedSteps,
        isUserResumingJourney,
        redirect
    };
};

export default useClaimantSignupJourney;