import React, { FC } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { createTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import DayJsUtils from "@date-io/dayjs";
import { AuthProvider } from "./lib/auth";
import * as Routes from "./lib/config";
import { UserProvider } from "./lib/user";
import {
  SecondaryContact,
  AddPhoneNumber,
  ConfirmIdentity,
  IdentityForm,
  Signature,
  SignIn,
  YotiError,
  Portal,
  ExcludeDefendants,
  Companies,
  Preferences,
  Homepage,
} from "./views";
import { AppTemplate } from "./templates";
import { ProtectedRoute } from "./components/Routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SuccessfulSignature } from "./views/SignUpJurney/SuccessfulSignature";
import { Check } from "./views/SignIn/Check";
import { YotiSuccess } from "./views/SignUpJurney/YotiRedirects";
import { SignUpTemplate } from "./templates";



const App: FC = () => {
  return (
    <div>
      <Switch>
        <Route exact path='/portal/:path?'>
          <Switch>
            <AppTemplate>
              <ProtectedRoute exact path={Routes.PORTAL} Component={Homepage} />
              <ProtectedRoute exact path={Routes.CASES} Component={Portal} />
              <ProtectedRoute exact path={Routes.COMPANIES} Component={Companies} />
              <ProtectedRoute exact path={Routes.SETTINGS} Component={Preferences} />
            </AppTemplate>
          </Switch>
        </Route>
        <Route exact path='/:path?'>
          <Switch>
            <SignUpTemplate>
              <Route exact path={Routes.HOME}>
                <SignIn />
              </Route>
              <Route exact path={Routes.SIGN_IN}>
                <SignIn />
              </Route>
              <Route exact path={Routes.VERIFICATION}>
                <AddPhoneNumber />
              </Route>
              <Route exact path={Routes.PRIMARY_CONTACT}>
                <AddPhoneNumber />
              </Route>
              <Route exact path={Routes.IDENTITY}>
                <IdentityForm />
              </Route>
              <Route exact path={Routes.YOTI_SUCCESS}>
                <YotiSuccess />
              </Route>
              <Route exact path={Routes.YOTI_ERROR}>
                <YotiError />
              </Route>
              <Route exact path={Routes.SECONDARY_CONTACT}>
                <SecondaryContact />
              </Route>
              <Route exact path={Routes.SELECT_DEFENDANTS}>
                <ExcludeDefendants />
              </Route>
              <Route exact path={Routes.CONFIRM_IDENTITY}>
                <ConfirmIdentity />
              </Route>
              <Route exact path={Routes.SIGNATURE}>
                <Signature />
              </Route>
              <Route exact path={Routes.COMPLETED_SIGNATURE}>
                <SuccessfulSignature />
              </Route>
              <Route exact path={Routes.CHECK}>
                <Check />
              </Route>
            </SignUpTemplate>
          </Switch>
        </Route>

      </Switch>
    </div>
  );
};

const theme = createTheme({
  typography: {
    fontFamily: `'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif`,
  },
  palette: {
    primary: {
      main: "#233861",
    },
    secondary: {
      main: "#7993c2",
    },
  },
  shape: { borderRadius: 8 }
});

const WrappedApp = () => (
  <>
    <CssBaseline />
    <BrowserRouter>
      <AuthProvider>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DayJsUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </UserProvider>
      </AuthProvider>
    </BrowserRouter>
  </>
);

export default WrappedApp;
