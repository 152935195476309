import React, { FC } from "react";
import {
  Grid,
  CircularProgress,
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Paper,
  makeStyles,
  Divider,
  StepIconProps,
} from "@material-ui/core";

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import useClaimantSignupJourney, { initialSteps } from "../../lib/hooks/useClaimantSignupJourney";
import { Alert, AlertTitle } from "@material-ui/lab";
import { SignUpContainer } from "../../templates/SignUpTemplate/SignUpContainer";
import FlagIcon from '@material-ui/icons/Flag';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  button: {
    padding: theme.spacing(2, 2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },

  },
  stepper: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  step: {
    marginBottom: theme.spacing(2),
  }
}));

const useQontoStepIconStyles = makeStyles((theme) => ({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  circle: {
    width: 8,
    height: 8,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 24,
  },
  current: {
    color: theme.palette.primary.main,
    zIndex: 1,
    fontSize: 24,
  },
}));

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CheckCircleIcon className={classes.completed} /> : active ? <FiberManualRecordIcon className={classes.current} /> : <div className={classes.circle} />}
    </div>
  );
}

function LastQontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { completed, active } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <FlagIcon className={classes.completed} /> : <EmojiFlagsIcon />}
    </div>
  );
}

type Props = {
  message?: string;
};

type StepperItem = {
  label: string;
  description: string;
  completed: boolean;
}

export const Check: FC<Props> = ({ message = "Signing In..." }) => {
  const classes = useStyles();
  const { steps, loading, currentStepIndex, completedSteps, isUserResumingJourney, redirect } = useClaimantSignupJourney();

  if (loading)
    return (
      <SignUpContainer image={null} title={message}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      </SignUpContainer>
    );

  if (isUserResumingJourney())
    return (
      <SignUpContainer image={null} title="">
        <Paper className={classes.paper}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                Welcome back
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" align="left" style={{ display: 'block' }} gutterBottom>
                You can resume the signup journey where you left off. Below you can see the progress you have made so far.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left">You have completed {completedSteps()} out of {initialSteps.length} steps.</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" align="center" style={{ display: 'block' }} gutterBottom>
                Signup journey progress
              </Typography>
              <Stepper connector={<></>} className={classes.stepper} activeStep={currentStepIndex} orientation="vertical">
                {steps.map(({ label, completed }, index) => (
                  <Step key={label} completed={completed}>
                    <StepLabel StepIconComponent={index !== steps.length - 1 ? QontoStepIcon : LastQontoStepIcon} className={classes.step}>
                      <Typography align="left">{label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => redirect()}
                endIcon={<ChevronRightIcon fontSize="large" />}
              >
                Resume signup journey
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </SignUpContainer>
    );

  return (
    <SignUpContainer image={null} title="">
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity="info">
              <AlertTitle><Typography variant="body1" align="left" style={{ display: 'block', textTransform: 'uppercase' }} gutterBottom>
                Account Created
              </Typography></AlertTitle>
              <Typography variant="caption" align="left" style={{ display: 'block' }} gutterBottom>
                You now have an account at Global Sports and Technology.
              </Typography>
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center">
              Signup Journey
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" align="left" style={{ display: 'block' }} gutterBottom>
              This signup journey will take approximately 10 minutes to complete.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" style={{ display: 'block' }} gutterBottom>
              Signup journey steps
            </Typography>
            <Stepper connector={<></>} className={classes.stepper} activeStep={currentStepIndex} orientation="vertical">
              {steps.map(({ label, completed }, index) => (
                <Step key={label} completed={completed}>
                  <StepLabel StepIconComponent={index !== steps.length - 1 ? QontoStepIcon : LastQontoStepIcon} className={classes.step}>
                    <Typography align="left">{label}</Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} className={classes.buttonGrid}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => redirect()}
              endIcon={<ChevronRightIcon fontSize="large" />}
            >
              Start signup journey
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </SignUpContainer>
  );
};
