import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import { Paper } from '@material-ui/core';
import { AccountCircle, Cake, LocationOn, Phone, PersonPinCircle } from '@material-ui/icons';
import { updateUserSettings } from '../../lib/api';
import { useAuthContext } from '../../lib/auth';
import { USER } from '../../lib/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    paper: {
      padding: theme.spacing(4, 4),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 1),
      },
      marginTop: 20,
      marginBottom: 20,
    },
    signOutButton: {
      display: 'block',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }
  })
);

export function Preferences() {
  const classes = useStyles();
  const theme = useTheme();
  const { accessToken } = useAuthContext();
  const [claimant, setClaimant] = React.useState<any>({})
  const [claimantSettings, setClaimantSettings] = React.useState<any>({ receiveNotifications: true });
  const [claimantProfile, setClaimantProfile] = React.useState<any>({});

  useEffect(() => {
    const _claimantJSON = localStorage.getItem(USER) || '{}';
    if (!_claimantJSON) return;
    const _claimant = JSON.parse(_claimantJSON);
    setClaimant(_claimant);
    setClaimantSettings(_claimant.claimantSettings || {});
    setClaimantProfile(_claimant.profile || {});
  }, [])

  const handleToggle = () => {
    const _newSettings = { ...claimantSettings, receiveNotifications: !claimantSettings.receiveNotifications };
    setClaimantSettings(_newSettings);
    updateUserSettings(accessToken, _newSettings)
      .then((res) => { setClaimantSettings(res.data.data); })
      .catch((err) => {
        console.log(err);
        setClaimantSettings({ ..._newSettings, receiveNotifications: !claimantSettings.receiveNotifications })
      });
  };

  return (
    <Paper className={classes.paper}>
      <Paper variant='outlined' square>
        <List
          subheader={<ListSubheader>Notification Settings</ListSubheader>}
          className={classes.root}
        >
          <ListItem>
            <ListItemIcon>
              <SmsIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary="SMS Notifications" secondary={claimant.phoneNumber} />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={handleToggle}
                checked={claimantSettings.receiveNotifications}
                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
      <Paper variant='outlined' square style={{ marginTop: theme.spacing(2) }}>
        <List
          subheader={<ListSubheader>User Details</ListSubheader>}
          className={classes.root}
        >
          <ListItem>
            <ListItemIcon>
              <AccountCircle />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={claimantProfile.fullName} secondary={'Full Name'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Cake />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={claimantProfile.dateOfBirth} secondary={'Date of Birth'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocationOn />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={claimantProfile.placeOfBirth} secondary={'Place of Birth'} />
          </ListItem>
        </List>
      </Paper>
      <Paper variant='outlined' square style={{ marginTop: theme.spacing(2) }}>
        <List
          subheader={<ListSubheader>Contact</ListSubheader>}
          className={classes.root}
        >
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={claimant.email} secondary={'Email'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={claimant.phoneNumber} secondary={'Phone Number'} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PersonPinCircle />
            </ListItemIcon>
            <ListItemText id="switch-list-label-wifi" primary={claimantProfile?.addresses && claimantProfile?.addresses[0]?.formattedAddress} secondary={'Current Address'} />
          </ListItem>
        </List>
      </Paper>
      <Button variant="contained" color="secondary" className={classes.signOutButton} style={{ width: '100%', marginTop: theme.spacing(1), backgroundColor: theme.palette.error.main, borderRadius: 0 }}>Sign out</Button>
    </Paper>
  );
}
