import axios, { AxiosPromise } from "axios";
import { API_ENDPOINT } from "../config/network";
import { YotiProfile, UserSettings } from "../user/types";
import { ApiMetaData } from "./types";

const baseUrl = API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;

type MessageArgs = {
    accessToken: string | null;
    caseId: string;
    topicId: string;
    message: MessagePayload;
    processId?: string;
};
type MessagePayload = {
    subject: string;
    content: string;
    files?: { fileId: string }[];
};

export const signUp = ({
    email,
    phone,
}: {
    email?: string;
    phone?: string;
}): AxiosPromise<ApiMetaData<{ id: string }>> =>
    axios({
        url: baseUrl + "user/claimant/signUp",
        method: "POST",
        data: { ...(email ? { email } : { phone }) },
        headers: {
            "x-api-key": apiKey,
        },
    });

export const resendVerificationCode = ({
    email,
    phone,
}: {
    email?: string;
    phone?: string;
}): AxiosPromise<ApiMetaData<{ id: string }>> =>
    axios({
        url: baseUrl + "user/claimant/resendCode",
        method: "POST",
        data: { email, phone },
        headers: {
            "x-api-key": apiKey,
        },
    });

export const logIn = ({
    email,
    phone,
}: {
    email?: string;
    phone?: string;
}): AxiosPromise<ApiMetaData<{ session: string }>> =>
    axios({
        url: baseUrl + "user/claimant/signIn",
        method: "POST",
        data: { ...(email ? { email } : { phone }) },
        headers: {
            "x-api-key": apiKey,
        },
    });

export const verifyLogIn = (
    {
        email,
        phone,
    }: {
        email?: string;
        phone?: string;
    },
    code: string,
    session: string
): AxiosPromise<ApiMetaData<{ AccessToken: string }>> =>
    axios({
        url: baseUrl + "user/claimant/confirmSignIn",
        method: "POST",
        data: { ...(email ? { email } : { phone }), code, session },
        headers: {
            "x-api-key": apiKey,
        },
    });

export const confirmSignUp = (
    id: string,
    code: string
): AxiosPromise<ApiMetaData<{ AccessToken: string }>> =>
    axios({
        url: baseUrl + "user/claimant/confirmSignUp",
        method: "POST",
        data: { id, code },
        headers: {
            "x-api-key": apiKey,
        },
    });

export const addAttribute = (
    accessToken: string,
    { email, phone }: { email?: string; phone?: string }
): AxiosPromise<ApiMetaData<any>> =>
    axios({
        url: baseUrl + "user/claimant/verifyAttribute",
        method: "POST",
        data: { ...(email ? { email } : { phone }) },
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const verifyAttribute = (
    accessToken: string,
    { email, phone }: { email?: string; phone?: string },
    code: string
): AxiosPromise<ApiMetaData<any>> =>
    axios({
        url: baseUrl + "user/claimant/confirmAttribute",
        method: "POST",
        data: { ...(email ? { email } : { phone }), code },
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const contactSupport = (
    accessToken: string,
    formdata: any,
): AxiosPromise<ApiMetaData<any>> =>
    axios({
        url: baseUrl + "user/claimant/contactSupport",
        method: "POST",
        data: formdata,
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

//Needs to be refactored
export const playerNameSearch = (name: string) => {
    return {
        data: [
            {
                name: "Cristiano Ronaldo",
                youthCareer: [
                    {
                        club: "Portugal",
                        from: "2019",
                        to: "2020",
                    },
                ],
                seniorCareer: [],
                nationalCareer: [],
            },
        ],
    };
};

export const getYotiUrl = (accessToken: string) =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimant/newYotiSession",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getLetterOfIntentUrl = (
    accessToken: string
): AxiosPromise<ApiMetaData<{ url: string; envelopeId: string, email: string }>> =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimant/getLetterOfIntentUrl",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const checkEnvelopeStatus = (
    email: string,
    envelopeId: string
): AxiosPromise<ApiMetaData<any>> =>
    axios({
        method: "POST",
        url: baseUrl + "envelopeStatus",
        data: { email, envelopeId },
    });

export const createPlayer = (accessToken: string, player: any) =>
    axios({
        method: "PUT",
        url: baseUrl + "user/claimant/player",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
        data: player,
    });

export const getYotiData = (
    accessToken: string
): AxiosPromise<ApiMetaData<{ profile: YotiProfile; yotiStatus: string }>> =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimant/progress",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getYotiFiles = (
    accessToken: string
): AxiosPromise<ApiMetaData<{ result: string[] }>> =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimant/getYotiDocuments",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getYotiresults = (
    accessToken: string
): AxiosPromise<ApiMetaData<{ result: string[] }>> =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimant/getYotiResults",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });


export const updateYotiSession = (
    accessToken: string
): AxiosPromise<ApiMetaData<{ result: string[] }>> =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimant/updateYotiSession",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const updateUserProfile = (
    accessToken: string,
    profile: YotiProfile
): AxiosPromise<ApiMetaData<YotiProfile>> =>
    axios({
        method: "PUT",
        url: baseUrl + "user/claimant/profile",
        data: profile,
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const updateUserSettings = (
    accessToken: string | null,
    settings: UserSettings
): AxiosPromise<ApiMetaData<UserSettings>> =>
    axios({
        method: "PUT",
        url: baseUrl + "user/claimant/settings",
        data: settings,
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getQuestions = (accessToken: string) =>
    axios({
        method: "GET",
        url: baseUrl + "question",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const uploadFile = (accessToken: string | null, formdata: any) =>
    axios({
        method: "POST",
        url: baseUrl + "file/claimant",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
        data: formdata,
    });

export const answerQuestions = (accessToken: string, answers: any) =>
    axios({
        method: "POST",
        url: baseUrl + "user/claimant/answer",
        data: answers,
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getCasesByClaimant = (accessToken: string) =>
    axios({
        method: "GET",
        url: baseUrl + "casesByClaimant",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getDefendants = (accessToken: string) =>
    axios({
        method: "GET",
        url: baseUrl + "user/defendant",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getClaimant = (accessToken: string) =>
    axios({
        method: "GET",
        url: baseUrl + "user/claimantByClaimant",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const setExcludedDefendants = (
    accessToken: string,
    claimantDefendants: any
) =>
    axios({
        method: "POST",
        url: baseUrl + "user/claimant/includedDefendants",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
        data: { claimantDefendants },
    });

export const mockYotiCallbackCallSessionCompletion = (yotiSessionId: string) =>
    axios({
        method: "POST",
        url: baseUrl + "user/claimant/handleYotiCallback",
        data: {
            session_id: yotiSessionId,
            topic: "session_completion",
        },
    });

export const mockYotiCallbackCallTaskCompletion = (yotiSessionId: string) =>
    axios({
        method: "POST",
        url: baseUrl + "user/claimant/handleYotiCallback",
        data: {
            session_id: yotiSessionId,
            topic: "task_completion",
        },
    });

export const getAddressPredictions = (
    accessToken: string,
    searchTerm: string
) =>
    axios({
        method: "GET",
        url: baseUrl + "getAddressPredictions?searchTerm=" + searchTerm,
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getAddressDetails = (accessToken: string, placeId: string) =>
    axios({
        method: "GET",
        url: baseUrl + "getAddressDetails?place_id=" + placeId,
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
    });

export const getMessagesForClaimantTopicId = (
    accessToken: string | null,
    caseId: string,
    topicId: string
): AxiosPromise<ApiMetaData<{ messages: any[] }>> =>
    axios({
        url: baseUrl + "case/messageByClaimant",
        method: "GET",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
        params: {
            CaseId: caseId,
            TopicId: topicId,
        },
    });

export const sendMessage = ({
    accessToken,
    caseId,
    topicId,
    message,
    processId,
}: MessageArgs): AxiosPromise =>
    axios({
        url: baseUrl + "case/messageByClaimant",
        method: "POST",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
        params: {
            CaseId: caseId,
            TopicId: topicId,
        },
        data: {
            subject: message.subject,
            content: message.content,
            files: message.files,
        },
    });

export const getFileById = (
    accessToken: string | null,
    fileId: string
): AxiosPromise =>
    axios({
        method: "GET",
        url: baseUrl + "fileByClaimant",
        headers: {
            Authorization: `${accessToken}`,
            "x-api-key": apiKey,
        },
        params: {
            fileId,
            download: true,
        },
    });
