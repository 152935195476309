import React, { useCallback, useEffect, useState } from 'react';
import {
  makeStyles,
  ButtonGroup,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Paper,
  Button,
  ListItemSecondaryAction,
  Typography,
  useTheme,
  Divider,
  Tab,
  Tabs,
  Box,
  Badge,
  Checkbox,
  FormControlLabel,
  Chip,
} from '@material-ui/core';


import { useAuthContext } from '../../lib/auth';

import {
  setExcludedDefendants as apiSetExcludedDefendants,
} from '../../lib/api';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
    marginTop: 20,
    marginBottom: 20,
  },
  loadingGrid: {
    minHeight: '50vh',
  },
  buttonBox: {
    textAlign: 'center',
    width: '100%',
    marginTop: '20px',
  },
  button: {
    padding: theme.spacing(2, 1),
  },
  spinner: {
    margin: '0 auto',
  },
  scrollY: {
    maxHeight: '60vh',
    overflowY: 'scroll',
  },
  defendantListItem: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  defendantListItemSecondaryAction: {
    right: 0,
  },
  excluded: {
    color: theme.palette.action.disabledBackground,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  chip: {
    cursor: 'not-allowed',
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} mt={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const Companies = (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const { accessToken } = useAuthContext();
  const [vettedDefendants, setVettedDefendants] = useState([] as any);
  const [notVettedDefendants, setNotVettedDefendants] = useState([] as any);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [value, setValue] = React.useState(0);
  const [confirmed, setConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (accessToken && props.claimant && props.defendants) {
      const _defendants = props.defendants || [];
      const claimantDefendantsMapped = props.claimant.claimantDefendants.map((claimantDefendant: any) => {
        const _defendant = _defendants.find(
          (defendant: any) => {
            return defendant.id === claimantDefendant.defendant
          }
        );
        return {
          defendant: _defendant,
          claimant: props.claimant,
          isIncluded: claimantDefendant.isIncluded,
        };
      });
      const notVettedDefendantsMapped = _defendants.filter((defendant: any) => !props.claimant.claimantDefendants.find(
        (claimantDefendant: any) => {
          return defendant.id === claimantDefendant.defendant
        }
      )).map((defendant: any) => {
        return {
          defendant: defendant,
          claimant: props.claimant,
          isIncluded: undefined,
        };
      });
      setVettedDefendants(claimantDefendantsMapped || []);
      setNotVettedDefendants(notVettedDefendantsMapped || []);
      setLoading(false);
    }
  }, [accessToken, props.claimant, props.defendants]);


  // check if any of the vetted defendants have undefined as isIncluded
  const undefinedDefendants = useCallback(() => {
    const undefinedDefendants = notVettedDefendants.filter((vettedDefendant: any) => vettedDefendant.isIncluded === undefined);
    return undefinedDefendants.length > 0;
  }, [notVettedDefendants]);

  useEffect(() => {
    if (!undefinedDefendants()) {
      setErrorMessage('');
    }
  }, [undefinedDefendants, vettedDefendants]);

  const confirmList = (confirmed: boolean) => {
    if (undefinedDefendants()) {
      setErrorMessage('Please include or exclude all defendants before submitting.');
    } else {
      setErrorMessage('');
      setConfirmed(confirmed);
    }
  };


  const handleClickDone = () => {
    if (vettedDefendants && accessToken) {
      setSubmitting(true);
      apiSetExcludedDefendants(accessToken, [...vettedDefendants, ...notVettedDefendants]).then((data) => {
        setNotVettedDefendants([]);
        setValue(0);
        setSubmitting(false);
        props.refreshClaimant();
      });
    }
  };

  const handleCheckDefendant = (defendant: any) => () => {
    const currentIndex = notVettedDefendants.findIndex(
      (vettedDefendant: any) => vettedDefendant.defendant.id === defendant.defendant.id
    );
    const newVettedDefendants = [...vettedDefendants];
    notVettedDefendants[currentIndex].isIncluded = !notVettedDefendants[currentIndex].isIncluded;
    setVettedDefendants(newVettedDefendants);
  };

  useEffect(() => {
    if (notVettedDefendants.length) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [props.claimant, props.defendants, notVettedDefendants]);

  return (
    <>
      <Paper className={classes.paper}>
        {loading ? (
          <Grid container alignItems="center" className={classes.loadingGrid}>
            <CircularProgress className={classes.spinner} />
          </Grid>
        ) : (
          <>
            <Tabs
              value={value}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
              aria-label="disabled tabs example"
            >
              <Tab label="Master List" />
              {notVettedDefendants.length && <Tab label={<Badge variant="dot" badgeContent='' color="error"><Typography>Action Required</Typography></Badge>} />}
            </Tabs>
            <TabPanel value={value} index={0}>
              <Typography
                variant="caption"
                style={{
                  textTransform: 'uppercase',
                }}
                component="p"
              >
                Already included/excluded companies.
              </Typography>
              <Divider />
              <List>
                {vettedDefendants.map((vettedDefendant: any) => (
                  <ListItem
                    key={vettedDefendant.defendant.id}
                    className={classes.defendantListItem}
                  >
                    <ListItemText
                      id={vettedDefendant.id}
                      primary={vettedDefendant.defendant.companyName}
                    />
                    <ListItemSecondaryAction
                      className={classes.defendantListItemSecondaryAction}
                    >
                      {vettedDefendant.isIncluded ? (
                        <Chip className={classes.chip} label="INCLUDED" color="primary" />
                      ) : (
                        <Chip className={classes.chip} label="EXCLUDED" variant='outlined' color="default" />
                      )}
                      {/* <ButtonGroup
                        size="small"
                        color="primary"
                        aria-label="outlined primary button group"
                      >
                        <Button
                          variant={
                            vettedDefendant.isIncluded
                              ? 'contained'
                              : 'outlined'
                          }
                          className={
                            vettedDefendant.isIncluded
                              ? ''
                              : classes.excluded
                          }
                        >
                          INCLUDED
                        </Button>
                        <Button
                          variant={
                            vettedDefendant.isIncluded
                              ? 'outlined'
                              : 'contained'
                          }
                          className={
                            vettedDefendant.isIncluded
                              ? classes.excluded
                              : ''
                          }
                        >
                          EXCLUDED
                        </Button>
                      </ButtonGroup> */}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </TabPanel>
            {notVettedDefendants.length ? (<TabPanel value={value} index={1}>
              <Typography
                variant="caption"
                style={{
                  textTransform: 'uppercase',
                }}
                component="p"
              >
                Please select companies to include/exclude.
              </Typography>
              <Divider />
              <List>
                {notVettedDefendants.map((vettedDefendant: any) => (
                  <ListItem
                    key={vettedDefendant.defendant.id}
                    className={classes.defendantListItem}
                  >
                    <ListItemText
                      id={vettedDefendant.id}
                      primary={vettedDefendant.defendant.companyName}
                    />
                    <ListItemSecondaryAction
                      className={classes.defendantListItemSecondaryAction}
                    >
                      {vettedDefendant.isIncluded === undefined ? (
                        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                          <Button variant="outlined" className={classes.excluded} onClick={handleCheckDefendant(vettedDefendant)}>INCLUDE</Button>
                          <Button variant="outlined" className={classes.excluded} onClick={handleCheckDefendant(vettedDefendant)}>EXCLUDE</Button>
                        </ButtonGroup>
                      ) : (
                        <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                          <Button variant={vettedDefendant.isIncluded ? 'contained' : 'outlined'} className={vettedDefendant.isIncluded ? '' : classes.excluded} onClick={handleCheckDefendant(vettedDefendant)}>INCLUDE</Button>
                          <Button variant={vettedDefendant.isIncluded ? 'outlined' : 'contained'} className={vettedDefendant.isIncluded ? classes.excluded : ''} onClick={handleCheckDefendant(vettedDefendant)}>EXCLUDE</Button>
                        </ButtonGroup>
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <FormControlLabel
                control={
                  <Checkbox
                    required
                    checked={confirmed}
                    onChange={() => confirmList(!confirmed)}
                    name="iamsure"
                    color="primary"
                  />
                }
                label={<Typography variant="caption" style={{ textTransform: 'uppercase', marginTop: theme.spacing(2), marginBottom: theme.spacing(2), textAlign: 'justify' }} >I have reviewed the list. Please take action against any of the included companies that violate my data rights.</Typography>}
              />
              {errorMessage && <Alert style={{ marginTop: 8 }} severity="error">{errorMessage}</Alert>}

              <Box className={classes.buttonBox}>
                <Button
                  className={classes.button}
                  disabled={submitting || !confirmed}
                  color="primary"
                  variant="contained"
                  onClick={handleClickDone}
                  fullWidth
                >
                  {!submitting ? 'SUBMIT' :
                    <CircularProgress />}
                </Button>
              </Box>
            </TabPanel>) : ''}
          </>
        )}
      </Paper >
    </>
  );
};
