import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Box,
  Button,
  Collapse,
  Grid,
  LinearProgress,
  makeStyles,
  TextField,
  Paper,
  Typography,
  Divider,
  useTheme,
} from "@material-ui/core";

import { AxiosError } from "axios";

import { addAttribute, verifyAttribute } from "../../../lib/api";
import { useAuthContext } from "../../../lib/auth";
import {
  USER_EMAIL_KEY,
  USER_PHONE_KEY,
  SELECT_DEFENDANTS,
  SIGN_IN,
} from "../../../lib/config";
import { useUserContext } from "../../../lib/user";
import { LoginType } from "../PrimaryContact/PrimaryContact";
import { parseUKPhoneNumber } from "../../../lib/helpers";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { SignUpContainer } from "../../../templates/SignUpTemplate/SignUpContainer";
import OTPInput from "../../../components/OTPInput";
import { HelperText } from "../../SignIn/HelperText";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  or: {
    display: "block",
    color: "rgba(0, 0, 0, 0.32)",
    letterSpacing: "-1px",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#fafafa",
    padding: 2,
  },
  logo: {
    margin: "0 auto",
    width: "auto",
    height: 128,
    padding: 24,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  button: {
    padding: theme.spacing(2, 1)
  },
  message: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  resendButton: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  otpQuestion: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

interface FormError {
  message: string;
  code: string;
}

const noError: FormError = {
  message: '',
  code: '',
};

export const SecondaryContact: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { accessToken } = useAuthContext();
  const { setEmail, setPhone, loginType } = useUserContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState<FormError>(noError);
  const [codeError, setCodeError] = useState<FormError>(noError);
  const [open, setOpen] = useState(false);
  const loggedInWithEmail = loginType === LoginType.EMAIL;


  const handleSubmit = () => {
    setLoading(true);

    if (!accessToken) return;
    addAttribute(accessToken, {
      ...(loggedInWithEmail ? { phone: parseUKPhoneNumber(userName) } : { email: userName }),
    })
      .then((_res) => {
        setLoading(false);
        setOpen(true);
      })
      .catch((error: AxiosError) => {
        console.dir(error);
        setLoading(false);
        if (error.message === "NotAuthorizedException") {
          return history.push(SIGN_IN);
        }
        const errorResponseBody = JSON.parse(error.request.response);
        switch (errorResponseBody.error.code) {
          case "USER_ALREADY_EXISTS":
            setUserNameError({ message: `User with this ${!loggedInWithEmail ? 'phone number' : 'email'} already exists.`, code: "USER_ALREADY_EXISTS" });
            break;
          case "LimitExceededException":
            setUserNameError({ message: 'You have exceeded the limit of sending verification code. Please try again later.', code: "LimitExceededException" });
            break;
          default:
            setUserNameError({ message: 'Something went wrong. Please try again.', code: "UNKNOWN_ERROR" });
            break;
        }
      });
  };

  const handleCodeSubmit = () => {
    if (!accessToken) return;

    setLoading(true);
    //verify the email
    verifyAttribute(
      accessToken,
      {
        ...(loggedInWithEmail ? { phone: userName } : { email: userName }),
      },
      code
    )
      .then((response) => {
        if (loggedInWithEmail) {
          setPhone(userName);
          localStorage.setItem(USER_PHONE_KEY, userName);
        } else {
          setEmail(userName);
          localStorage.setItem(USER_EMAIL_KEY, userName);
        }
        setLoading(false);
      })
      .then(() => history.push(SELECT_DEFENDANTS))
      .catch((error) => {
        console.log(error);
        const errorResponseBody = JSON.parse(error.response);
        switch (errorResponseBody.error.code) {
          case "CodeMismatchException":
            setCodeError({ message: "Incorrect code entered.", code: "INCORRECT_CODE" });
            break;
          case "ExpiredCodeException":
            setCodeError({ message: "Code has expired.", code: "EXPIRED_CODE" });
            break;
          case "LimitExceededException":
            setCodeError({ message: 'You have exceeded the limit of sending verification code. Please try again later.', code: "LimitExceededException" });
            break;
          default:
            setCodeError({ message: errorResponseBody.error.message, code: "UNKNOWN_ERROR" });
            break;
        }
        setLoading(false);
      });
  };

  const resendCode = () => {
    setCodeError(noError);
    setCode("");
    handleSubmit();
  };

  return (
    <SignUpContainer image={null} title="">
      <Paper className={classes.paper}>
        {/* <Slogan text="We support your rights as an athlete" /> */}
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            !open ? handleSubmit() : handleCodeSubmit()
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={!open}>
                <>
                  <Box>
                    {!loggedInWithEmail ? <MailOutlineIcon className={classes.title} style={{ fontSize: '6em' }} />
                      : <SmartphoneIcon className={classes.title} style={{ fontSize: '6em' }} />}
                    <Typography align="center" style={{ marginBottom: theme.spacing(2) }}>Let's verify your {loggedInWithEmail ? 'phone number.' : 'email.'}</Typography>
                  </Box>
                  <TextField
                    error={!!userNameError.message}
                    fullWidth
                    helperText={userNameError.message}
                    id="email-address"
                    label={loggedInWithEmail ? "Phone" : "Email"}
                    name="email-address"
                    onChange={(e) => {
                      if (!!userNameError.message) setUserNameError(noError);
                      setUserName(e.target.value);
                    }}
                    required
                    type={loggedInWithEmail ? "tel" : "email"}
                    value={userName}
                    variant="outlined"
                  />
                </>
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              {/* <Collapse in={open}>
                <TextField
                  error={!!codeError}
                  id="email-otp"
                  label="One Time Code"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    autoCapitalize: 'none',
                    autoCorrect: 'off',
                    inputMode: "numeric"
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginRight: 0,
                    },
                  }}
                  helperText={
                    !!codeError
                      ? (<Box>{codeError} <Button size="small" onClick={resendCode} className={classes.resendButton} endIcon={<AutorenewIcon />}>Resend Code</Button> </Box>)
                      : `Enter the code from the ${loggedInWithEmail ? 'SMS' : 'email'} we've sent.`
                  }
                  onChange={(e) => {
                    if (!!codeError) setCodeError(null);
                    setCode(e.target.value);
                  }}
                  value={code}
                />
              </Collapse> */}
              <Collapse in={open}>
                <>
                  {/* <TextField
                  error={!!otpError.message}
                  id="standard-basic"
                  label="One Time Code"
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    autoCapitalize: 'none',
                    autoCorrect: 'off',
                    inputMode: "numeric"
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginRight: 0,
                    },
                  }}
                  helperText={<HelperText
                    errorMessage={otpError.message}
                    isError={!!otpError.message}
                    // helperText={`Enter the code from the ${email.current ? 'email' : 'SMS'} we sent you.`}
                    button={resolveOtpButton()} />
                  }
                  onChange={(e) => {
                    if (!!otpError.message) setOTPError(defaultError);
                    setCode(e.target.value);
                  }}
                  value={code}
                /> */}
                  <Box>
                    {!loggedInWithEmail ? <MailOutlineIcon className={classes.title} style={{ fontSize: '6em' }} />
                      : <SmartphoneIcon className={classes.title} style={{ fontSize: '6em' }} />}
                    <Typography align="center" className={classes.title}>Verification code</Typography>
                  </Box>
                  <Divider />
                  {!loggedInWithEmail ? <Typography align="left" className={classes.message}>
                    Please enter 6-digit code that was sent to {userName || 'your email'}.
                  </Typography> : <Typography align="left" className={classes.message}>
                    Please enter 6-digit code that was sent to {userName || 'your phone via SMS'}.
                    {/* <Button variant="outlined" className={classes.resendButton} onClick={resendCode}>Resend code.</Button> */}
                  </Typography>}

                  <OTPInput
                    autoFocus
                    length={6}
                    inputStyle={codeError.message ? { border: '1px solid red' } : {}}
                    style={{ marginTop: 32, marginBottom: 32 }}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={(e) => {
                      if (!!codeError.message) setCodeError(noError);
                      setCode(e);
                      console.log(e);
                    }}
                  />
                  <HelperText
                    errorMessage={codeError.message}
                    isError={!!codeError.message} />
                  <Typography align="left" className={classes.otpQuestion}>
                    Did not receive the verification code? <span className={classes.resendButton} onClick={resendCode}>Resend code.</span>
                  </Typography>
                </>
              </Collapse>
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Button
              className={classes.button}
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {!open
                ? `Submit ${loggedInWithEmail ? "Phone" : "Email"}`
                : "Submit Code"}
            </Button>
            {loading && <LinearProgress color="secondary" />}
          </Box>
        </form>
      </Paper>
    </SignUpContainer>
  );
};
