import React, { FC, useEffect, useState } from 'react';

import { Box, LinearProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { MessageThread } from './MessageThread';
import { getMessagesForClaimantTopicId } from '../../lib/api';
import { useAuthContext } from '../../lib/auth';

type Props = {
  caseId: string;
  topicId: string;
};

export const Thread: FC<Props> = ({ caseId, topicId }) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Array<any>>([]);
  const [error, setError] = useState(false);
  const { accessToken } = useAuthContext();

  useEffect(() => {
    if (!accessToken || !caseId || !topicId) return;
    setLoading(true);
    getMessagesForClaimantTopicId(accessToken, caseId, topicId)
      .then((res) => {
        setMessages(res.data.data.messages.reverse());
        setLoading(false);
      })
      .catch((error) => setError(true));
  }, [accessToken, caseId, topicId]);

  // const refreshMessages = () => {
  //   if (!accessToken || !caseId || !topicId) return;
  //   setRefreshing(true);
  //   getMessagesForClaimantTopicId(accessToken, caseId, topicId)
  //     .then((res) => {
  //       setMessages(res.data.data.messages.reverse());
  //       setRefreshing(false);
  //     })
  //     .catch((error) => {
  //       setError(true);
  //       setRefreshing(false);
  //     });
  // };

  if (loading)
    return (
      <Box padding={4}>
        <Skeleton variant="text" height={40} width={'100%'} />
        <Skeleton variant="text" height={20} width={'30%'} />
        <Skeleton variant="text" height={20} width={'65%'} />
        <LinearProgress />
        {/* <MessageEditor
                caseId={caseId}
                topicId={topicId}
                refreshMessages={refreshMessages}
                /> */}
      </Box>
    );

  if (error || !messages) return <MessageThread messages={[]} />;

  return (
    <>
      <MessageThread messages={messages} />
      {/* {refreshing && <LinearProgress />} */}
      {/* <MessageEditor
                caseId={caseId}
                topicId={topicId}
                refreshMessages={refreshMessages}
            /> */}
    </>
  );
};
