import React, { useState, useEffect, FC } from "react";
import { useHistory } from "react-router-dom";
import { COMPLETED_SIGNATURE, SIGN_IN, VERIFICATION } from "../config/routes";
import { ACCESS_TOKEN_KEY } from "../config/localStorage";
import { createGenericContext } from "../context";
import { getItem } from "../helpers/localstorage";

type AuthContextType = {
  accessToken: string | null;
  sessionId: string;
  sessionToken: string;
  setAccessToken: (token: string | null) => void;
  setSessionId: (id: string) => void;
  setSessionToken: (id: string) => void;
};
const [
  useAuthContext,
  AuthContextProvider,
] = createGenericContext<AuthContextType>();

const AuthProvider: FC = ({ children }) => {
  const history = useHistory();
  const [sessionToken, setSessionToken] = useState<string>("");
  const [sessionId, setSessionId] = useState<string>("");
  const [accessToken, setAccessToken] = useState<string | null>(
    getItem(ACCESS_TOKEN_KEY)
  );

  useEffect(() => {
    // Need this because other people who sign the document might end up on this page
    if (history.location.pathname === COMPLETED_SIGNATURE || history.location.pathname === VERIFICATION) return;
    if (!accessToken) {
      const token = getItem(ACCESS_TOKEN_KEY);
      !!token ? setAccessToken(token) : history.replace(SIGN_IN);
    }
  }, [accessToken, history]);

  return (
    <AuthContextProvider
      value={{
        accessToken,
        setAccessToken,
        sessionToken,
        setSessionToken,
        sessionId,
        setSessionId,
      }}
    >
      {children}
    </AuthContextProvider>
  );
};

export { useAuthContext, AuthProvider };
