import React, { FC, useCallback, useEffect, useState } from "react";
import {
  makeStyles,
  ButtonGroup,
  Grid,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  ListItemSecondaryAction,
  Typography,
  useTheme,
  Divider,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@material-ui/core";

import { useAuthContext } from "../../../lib/auth";

import {
  getDefendants,
  setExcludedDefendants as apiSetExcludedDefendants,
} from "../../../lib/api";

import { Alert, AlertTitle } from "@material-ui/lab";
import { SignUpContainer } from "../../../templates/SignUpTemplate/SignUpContainer";
import { CONFIRM_IDENTITY } from "../../../lib/config";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  loadingGrid: {
    minHeight: "50vh",
  },
  buttonBox: {
    textAlign: "center",
    width: "100%",
    marginTop: "20px",
  },
  button: {
    padding: theme.spacing(2, 1)
  },
  spinner: {
    margin: "0 auto",
  },
  defendantListItem: {
    paddingRight: 0,
    paddingLeft: 0,
  },
  defendantListItemSecondaryAction: {
    right: 0,
  },
  excluded: {
    color: theme.palette.action.active,
    backgroundColor: theme.palette.action.disabledBackground,
  },
  errorText: {
    color: theme.palette.error.main,
  }
}));


export const ExcludeDefendants: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { accessToken } = useAuthContext();
  const [defendants, setDefendants] = useState(null as any);
  const [vettedDefendants, setVettedDefendants] = useState([] as any);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const submitBtnRef = React.useRef<HTMLButtonElement>(null);
  const [buttonInView, setButtonInView] = useState<boolean>(false);
  const [listItemErrors, setListItemErrors] = useState({} as any);

  // check if submitBtnRef is outside of viewport
  useEffect(() => {
    // Check if button is in viewport
    const checkIfInView = () => {
      if (submitBtnRef.current === null) return;
      const rect = submitBtnRef.current?.getBoundingClientRect();
      const isInView = rect?.top >= 0 && rect?.left >= 0 && rect?.bottom <= window.innerHeight && rect?.right <= window.innerWidth;
      if (isInView !== buttonInView) setButtonInView(isInView);
    }

    // Call the function initially
    checkIfInView();

    // Call the function whenever the window is scrolled or resized
    window.addEventListener('scroll', checkIfInView);
    window.addEventListener('resize', checkIfInView);

    // Clean up function
    return () => {
      window.removeEventListener('scroll', checkIfInView);
      window.removeEventListener('resize', checkIfInView);
    }
  }, [buttonInView]);


  useEffect(() => {
    if (accessToken) {
      getDefendants(accessToken).then((data) => {
        const _defendants = data.data.data;
        setDefendants(_defendants);
        const notVettedDefendantsMapped = _defendants.map((defendant: any) => {
          return {
            defendant: defendant,
            claimant: undefined,
            isIncluded: undefined,
          };
        });
        setVettedDefendants(notVettedDefendantsMapped || []);
        setLoading(false);
      });
    }
  }, [accessToken]);

  // check if any of the vetted defendants have undefined as isIncluded
  const undefinedDefendants = useCallback(() => {
    const undefinedDefendants = vettedDefendants.filter((vettedDefendant: any, index: number) => vettedDefendant.isIncluded === undefined);
    return undefinedDefendants.length > 0;
  }, [vettedDefendants]);

  const handleSubmit = () => {
    setSubmitting(true);
    if (undefinedDefendants()) {
      alert('Please include or exclude all defendants.');
      setSubmitting(false);
      return;
    }
    apiSetExcludedDefendants(accessToken || '', vettedDefendants).then(() => {
      history.push(CONFIRM_IDENTITY);
      setSubmitting(false);
    });
  };

  const includeAll = (_defendants: any) => {
    const defendantsMapped = _defendants.map((defendant: any) => {
      return {
        defendant: defendant,
        claimant: undefined,
        isIncluded: true,
      };
    });
    setListItemErrors({});
    setVettedDefendants(defendantsMapped);
  }

  const handleToggleDefendant = (defendant: any) => () => {
    const currentIndex = vettedDefendants.findIndex(
      (vettedDefendant: any) => vettedDefendant.defendant.id === defendant.defendant.id
    );
    setListItemErrors((prevState: any) => { return { ...prevState, [defendant.defendant.id]: false } });
    const newVettedDefendants = [...vettedDefendants];
    vettedDefendants[currentIndex].isIncluded = !vettedDefendants[currentIndex].isIncluded;
    setVettedDefendants(newVettedDefendants);
  };

  const handleIncludeDefendant = (defendant: any) => () => {
    const currentIndex = vettedDefendants.findIndex(
      (vettedDefendant: any) => vettedDefendant.defendant.id === defendant.defendant.id
    );
    setListItemErrors((prevState: any) => { return { ...prevState, [defendant.defendant.id]: false } });
    const newVettedDefendants = [...vettedDefendants];
    vettedDefendants[currentIndex].isIncluded = true;
    setVettedDefendants(newVettedDefendants);
  };

  const handleExcludeDefendant = (defendant: any) => () => {
    const currentIndex = vettedDefendants.findIndex(
      (vettedDefendant: any) => vettedDefendant.defendant.id === defendant.defendant.id
    );
    setListItemErrors((prevState: any) => { return { ...prevState, [defendant.defendant.id]: false } });
    const newVettedDefendants = [...vettedDefendants];
    vettedDefendants[currentIndex].isIncluded = false;
    setVettedDefendants(newVettedDefendants);
  };

  useEffect(() => {
    if (!undefinedDefendants()) {
      setErrorMessage('');
    }
  }, [undefinedDefendants, vettedDefendants]);

  const confirmList = (confirmed: boolean) => {
    if (undefinedDefendants()) {
      vettedDefendants.forEach((vettedDefendant: any) => {
        if (vettedDefendant.isIncluded === undefined)
          setListItemErrors((prevState: any) => { return { ...prevState, [vettedDefendant.defendant.id]: true } });

      });
      setErrorMessage('All companies must be included or excluded all continuing.');
    } else {
      setErrorMessage('');
      setConfirmed(confirmed);
    }
  };

  if (loading)
    return (
      <SignUpContainer image={null} title="">
        <Paper variant="outlined">
          <Grid container alignItems="center" className={classes.loadingGrid}>
            <CircularProgress className={classes.spinner} />
          </Grid>
        </Paper>
      </SignUpContainer>
    );

  return (
    <>
      <SignUpContainer image={null} title="">
        <Alert severity="warning" variant="outlined" color="info">
          <AlertTitle>
            <Typography variant="body1" align="left" style={{ display: 'block', textTransform: 'uppercase' }} gutterBottom>
              Taking Action
            </Typography>
          </AlertTitle>
          <Typography align="left" style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} component="p">On your behalf, we can take action against the following betting agencies if they are violating your data rights.</Typography>
          <Typography align="left" style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} component="p">However, we will not take action against any company that you choose to exclude from this list.</Typography>
        </Alert>
        <Paper variant="outlined" className={classes.paper} style={{ marginTop: theme.spacing(1) }}>
          <Typography align="left" variant="caption" style={{ textTransform: 'uppercase', marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }} component="p">Please review the following list and include any companies that you want us to take action against.</Typography>
          <Grid justifyContent="flex-end" container style={{ marginBottom: 8 }}>
            <Button size="small" variant="contained" color="primary" onClick={() => includeAll(defendants)}>Include all</Button>
          </Grid>
          <Divider />
          <List>
            {vettedDefendants.map((vettedDefendant: any) => (
              <ListItem button onClick={handleToggleDefendant(vettedDefendant)} key={vettedDefendant.defendant.id} className={classes.defendantListItem}>
                <ListItemText id={vettedDefendant.defendant.id} primary={vettedDefendant.defendant.companyName} secondary={listItemErrors[vettedDefendant.defendant.id] ? <Typography variant="caption" className={classes.errorText}>Must be included of excluded.</Typography> : ''} />
                <ListItemSecondaryAction className={classes.defendantListItemSecondaryAction}>
                  {vettedDefendant.isIncluded === undefined ? (
                    <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                      <Button style={{ border: listItemErrors[vettedDefendant.defendant.id] ? `1px solid ${theme.palette.error.main}` : '' }} variant="outlined" className={classes.excluded} onClick={handleIncludeDefendant(vettedDefendant)}>INCLUDE</Button>
                      <Button style={{ border: listItemErrors[vettedDefendant.defendant.id] ? `1px solid ${theme.palette.error.main}` : '' }} variant="outlined" className={classes.excluded} onClick={handleExcludeDefendant(vettedDefendant)}>EXCLUDE</Button>
                    </ButtonGroup>
                  ) : (
                    <ButtonGroup size="small" color="primary" aria-label="outlined primary button group">
                      <Button variant={vettedDefendant.isIncluded ? 'contained' : 'outlined'} className={vettedDefendant.isIncluded ? '' : classes.excluded} onClick={handleIncludeDefendant(vettedDefendant)}>INCLUDE</Button>
                      <Button variant={vettedDefendant.isIncluded ? 'outlined' : 'contained'} className={vettedDefendant.isIncluded ? classes.excluded : ''} onClick={handleExcludeDefendant(vettedDefendant)}>EXCLUDE</Button>
                    </ButtonGroup>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Paper>
        <Paper variant="outlined" className={classes.paper} style={{ marginTop: theme.spacing(1), }}>
          <FormControlLabel
            style={{ textAlign: 'left' }}
            control={
              <Checkbox
                required
                checked={confirmed}
                onChange={() => confirmList(!confirmed)}
                name="iamsure"
                color="primary"
              />
            }
            label={<Typography variant="caption" align="left" style={{ textTransform: 'uppercase' }}>I have reviewed the list. Please take action against any of the included companies that violate my data rights.</Typography>}
          />
          {errorMessage && <Alert style={{ marginTop: 8, textAlign: 'left' }} severity="error">{errorMessage}</Alert>}
          <Box className={classes.buttonBox}>
            <Button
              className={classes.button}
              disabled={submitting || !confirmed}
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              ref={submitBtnRef}
            >
              {!submitting ? 'SUBMIT' :
                <CircularProgress />}
            </Button>
          </Box>
        </Paper>
      </SignUpContainer>
      {!buttonInView && <Box style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <ExpandMoreIcon fontSize="large" />
      </Box>}
    </>
  );
};
