import { Redirect, RouteProps } from "react-router";

import React from "react";
import { Route } from "react-router-dom";
// import { SIGN_IN } from '../../constants/routes';
import { useAuthContext } from "../../lib/auth";
import { HOME } from "../../lib/config";

interface ProtectedRouteProps extends RouteProps {
  Component: React.ComponentType | React.ElementType;
}
export const ProtectedRoute = ({
  Component,
  ...props
}: ProtectedRouteProps) => {
  const { accessToken } = useAuthContext();

  return (
    <Route
      {...props}
      render={() => (accessToken ? <Component {...props} /> : <Redirect to={HOME} />)}
    />
  );
};
