import React, { Children, FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AppBar,
  Container,
  Divider,
  Drawer,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import { BottomNavigation } from './BottomNavigation';

import { MainNavigation } from './MainNavigation';
import logoModified from "../../assets/logo-modified.svg";
import logo from "../../assets/logo.svg";
import styles from './AppTemplate.module.css';
import { useAuthContext } from '../../lib/auth';
import {
  ACCESS_TOKEN_KEY,
  USER_PHONE_KEY,
  USER_EMAIL_KEY,
  USER,
  LOGIN_TYPE
} from '../../lib/config/localStorage';
import { COMPANIES, HOME } from '../../lib/config/routes';
import { getClaimant, getDefendants } from '../../lib/api';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    background: 'rgba(35, 56, 97, 1)',
  },
  drawer: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  menuBar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  signOutButton: {
    marginLeft: 'auto',
  },
  bottomNavigation: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  appbarLogo: {
    display: 'block',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(2),
    },
  }
}))

export const AppTemplate: FC = ({ children }) => {
  const classes = useStyles();
  const history = useHistory();
  const { accessToken, setAccessToken } = useAuthContext();
  const [claimant, setClaimant] = React.useState<any>(null);
  const [defendants, setDefendants] = React.useState<any>(null);
  const [notifications, setNotifications] = React.useState({});

  useEffect(() => {
    if (!accessToken) return;
    fetchClaimant(accessToken);
  }, [accessToken]);

  const fetchClaimant = (accessToken: string) => {
    if (!accessToken) return;
    getClaimant(accessToken || '')
      .then((response: any) => {
        const data = response.data.data;
        setClaimant(data);
        localStorage.setItem(USER, JSON.stringify(data));
      })
      .catch((error) => {
        console.log(error);
        const errorResponseBody = JSON.parse(error.request.response);
        console.error(errorResponseBody);
      });
  }

  const fetchDefendants = (accessToken: string, claimant: any) => {
    if (!accessToken) return;
    getDefendants(accessToken).then((data) => {
      const _defendants = data.data.data;
      setDefendants(_defendants);
      if (_defendants.length > claimant.claimantDefendants.length) {
        setNotifications({ [COMPANIES]: true });
      } else {
        setNotifications({ [COMPANIES]: false });
      }
    });
  }

  useEffect(() => {
    if (!claimant || !accessToken) return;
    fetchDefendants(accessToken, claimant);
  }, [accessToken, claimant]);

  const refreshDefendants = () => {
    if (!accessToken || !claimant) return;
    fetchDefendants(accessToken, claimant);
  }

  const refreshClaimant = () => {
    if (!accessToken) return;
    fetchClaimant(accessToken);
  }

  const signOut = () => {
    setAccessToken(null);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(USER_EMAIL_KEY);
    localStorage.removeItem(USER_PHONE_KEY);
    localStorage.removeItem(LOGIN_TYPE);
    localStorage.removeItem(USER);
    history.push(HOME);
  };

  if (!children) return <></>;

  return (
    <>
      <main className={styles.root}>
        <AppBar position="fixed">
          <Toolbar className={classes.toolbar} />
          <a href={HOME} className={classes.appbarLogo} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
              <img
                style={{ padding: '5px', margin: 'auto' }}
                src={logoModified}
                alt="Tuxteam logo"
                height="64px"
              />
            </div>
          </a>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <div className={classes.toolbar}>
            <img src={logo} alt="logo" width={120} />
          </div>
          <Divider />
          <div className={classes.drawerContainer}>
            <MainNavigation notifications={notifications} signOut={signOut} />
          </div>
        </Drawer>
        <section className={styles.main}>
          <Container maxWidth={false} className={classes.container}>
            {Children.toArray(children).map((child: any) => React.cloneElement(child, { claimant, refreshClaimant, defendants, refreshDefendants }))}
          </Container>
        </section>
      </main>
      <BottomNavigation notifications={notifications} signOut={signOut} />
    </>
  );
};
