import React, { FC } from "react";

import {
    CircularProgress,
    Grid,
} from "@material-ui/core";

import {
    PdfLoader,
    PdfHighlighter,
    IHighlight,
} from "react-pdf-highlighter";

type Props = {
    fileURL: string;
    highlights: Array<IHighlight>;
};

export const PDFViewer: FC<Props> = ({
    fileURL,
    highlights,
}) => {
    return (
        <div
            style={{
                height: "calc(100vh - 128px)",
                width: "100%",
                position: "relative",
            }}
        >
            <PdfLoader
                url={fileURL}
                beforeLoad={
                    <Grid
                        container
                        style={{ height: "550px" }}
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <CircularProgress />
                    </Grid>
                }
            >
                {(pdfDocument) => {
                    return (
                        <PdfHighlighter
                            pdfDocument={pdfDocument}
                            enableAreaSelection={() => false}
                            onScrollChange={() => { }}
                            pdfScaleValue="page-width"
                            scrollRef={() => { }}
                            onSelectionFinished={() => (<></>)}
                            highlightTransform={() => (<></>)}
                            highlights={highlights}
                        />
                    );
                }}
            </PdfLoader>
        </div>
    );
};
