import React, { FC, useState } from 'react';

import {
  Avatar,
  Box,
  Chip,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  CallMade,
  CallReceived,
  PictureAsPdfOutlined,
} from '@material-ui/icons';
import { getFileById } from '../../lib/api';
import { useAuthContext } from '../../lib/auth';
import noUpdates from '../../assets/notification-stop-svgrepo-com.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  blue: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export type Message = {
  id: string;
  //message main attributes
  from: string;
  to: string;
  sentDate: string;
  subject: string;
  content: string;
  type: string;
  status: string;
  attachments: Attachment[];

  //message status
  sent: boolean;
  delivered: boolean;
  bounced: boolean;
  complaint: boolean;
};

type Attachment = {
  id: string;
  fileId: string;
  name: string;
};

type Props = {
  messages: Message[];
};
export const MessageThread: FC<Props> = ({ messages }) => {
  const classes = useStyles();
  const { accessToken } = useAuthContext();
  const [loading, setLoading] = useState<string[]>([]);

  const handleGetMessageAttachment = (attachmentId: string) => {
    setLoading([...loading, attachmentId]);
    getFileById(accessToken, attachmentId)
      .then((res) => res.data.data.url)
      .then((url) => window.open(url));
  };

  if (messages.length === 0)
    return (
      <Box
        style={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100%',
          alignItems: 'center',
        }}
      >
        <img
          src={noUpdates}
          alt="no updates"
          style={{ width: '20%' }}
        />
        <Typography style={{ textTransform: 'uppercase' }} component="p">
          There are no updates for this case.
        </Typography>
        <Typography
          variant="caption"
          style={{ textTransform: 'uppercase' }}
          component="p"
        >
          Once there are updates you will be notified by SMS.
        </Typography>
      </Box>
    );

  return (
    <List className={classes.root}>
      {messages.map((m, i) => (
        <ListItem
          key={m.id}
          alignItems="flex-start"
          divider={i !== messages.length - 1}
        >
          <ListItemAvatar>
            <Avatar className={m.type === 'INCOMING' ? classes.blue : ''}>
              {m.type === 'INCOMING' ? <CallMade /> : <CallReceived />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            disableTypography
            primary={
              <>
                <Typography>
                  <b>{m.subject}</b>
                </Typography>
                {m.from.search('mylifedata.co.uk') < 0 && (
                  <small>
                    <em>{m.from}</em>
                  </small>
                )}
              </>
            }
            secondary={
              <>
                <Box
                  style={{ backgroundColor: 'var(--base-white)' }}
                  paddingY={1}
                  paddingX={2}
                  marginTop={2}
                  marginBottom={2}
                >
                  <div dangerouslySetInnerHTML={{ __html: m.content }} />
                </Box>
                <Box>
                  {m.attachments.length > 0 &&
                    m.attachments.map((a) => {
                      const isLoading = loading.indexOf(a.id) > -1;
                      return (
                        <Chip
                          disabled={isLoading}
                          key={a.id}
                          label={isLoading ? 'Downloading...' : a.name}
                          icon={<PictureAsPdfOutlined />}
                          onClick={() => handleGetMessageAttachment(a.fileId)}
                          variant="outlined"
                        />
                      );
                    })}
                </Box>
              </>
            }
          />
        </ListItem>
      ))}
    </List>
  );
};
