export const isValidPhoneNumber = (n: string): boolean =>
  /^(\+44\s?7(\d ?){3}|\(?07(\d ?){3}\)?)\s?(\d ?){3}\s?(\d ?){3}$/.test(n);

export const parseUKPhoneNumber = (mobile: string) => {
  if (mobile.startsWith('0')) {
    return mobile.replace('0', '+44');
  }
  if (!mobile.startsWith('+44')) {
    return `+44${mobile}`;
  }
  return mobile;
};

export const isValidEmail = (n: string): boolean =>
  /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(n);

/**
 * @returns dateOfBirth -> date_of_birth
 * @param str
 */
export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

/**
 * @returns dateOfBirth -> Date Of Birth
 * @param str
 */
export const camelCaseToNormal = (str: string) => {
  return str.replace(/([A-Z])/g, ' $1').replace(/^./, char => {
    return char.toUpperCase();
  });
};

export const isDateValid = (dateStr: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;

  if (dateStr.match(regex) === null) {
    return false;
  }

  const date = new Date(dateStr);

  const timestamp = date.getTime();

  if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(dateStr);
}
