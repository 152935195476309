import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  TextField,
  Grid,
  Typography,
  makeStyles,
  Box,
  Divider,
  Collapse,
  LinearProgress,
  Paper,
  useTheme,
} from "@material-ui/core";

import {
  ACCESS_TOKEN_KEY,
  PRIMARY_CONTACT,
  USER_EMAIL_KEY,
  USER_PHONE_KEY,
  LOGIN_TYPE,
  USER,
  CHECK,
  VERIFICATION
} from "../../lib/config";
import { logIn, verifyLogIn } from "../../lib/api";
import { useAuthContext } from "../../lib/auth";
import { isValidEmail, isValidPhoneNumber, parseUKPhoneNumber } from "../../lib/helpers";
import { useUserContext } from "../../lib/user";
import { SignUpContainer } from "../../templates/SignUpTemplate/SignUpContainer";
import { HelperText } from "./HelperText";
import OTPInput from "../../components/OTPInput";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { Slogan } from '../../components/Slogan';

const useStyles = makeStyles((theme) => ({
  or: {
    display: "block",
    color: "rgba(0, 0, 0, 0.42)",
    letterSpacing: "-1px",
    fontSize: 12,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: "#ffffff",
    padding: theme.spacing(0, 1, 0, 1),
  },
  paper: {
    width: '100%',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 2),
    },
  },
  logo: {
    margin: "0 auto",
    width: "auto",
    height: 128,
    padding: 24,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
  },
  button: {
    padding: theme.spacing(2, 1)
  },
  slogan: {
    width: '100%',
    textAlign: 'center',
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    fontSize: '1rem',
  },
  message: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.text.secondary,
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
  },
  resendButton: {
    fontWeight: 700,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  otpQuestion: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  description: {
    width: '100%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2)
  },
}));

interface FormError {
  message: string;
  code: string;
}

const defaultError: FormError = {
  message: '',
  code: '',
};

export const SignIn = () => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { setAccessToken, setSessionToken, sessionToken } = useAuthContext();
  const { setEmail, setPhone } = useUserContext();

  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const [userNameError, setUserNameError] = useState<FormError>(defaultError);
  const [otpError, setOTPError] = useState<FormError>(defaultError);
  const email = useRef("");
  const phone = useRef("");
  const queryParameters = new URLSearchParams(window.location.search);

  const handleAutoLogin = useCallback((username: string) => {
    setLoading(true);
    if (!isValidEmail(username) && !isValidPhoneNumber(username)) {
      setUserNameError({ message: 'Invalid email or phone number.', code: 'INVALID_FORMAT' });
      setLoading(false);
      return;
    }
    if (isValidEmail(username)) email.current = username;
    else {
      phone.current = parseUKPhoneNumber(username);
    }
    logIn({ email: email.current, phone: phone.current })
      .then((response) => setSessionToken(response.data.data.session))
      .then(() => {
        setLoading(false);
        setOpen(true);
      })
      .catch((error) => {
        const errorResponseBody = JSON.parse(error.request.response);
        switch (errorResponseBody.error.code) {
          case "UserNotFoundException": setUserNameError({ message: `User with this ${email.current ? 'email' : 'phone number'} does not exist.`, code: 'UserNotFoundException' }); break;
          case "USER_NOT_CONFIRMED": setUserNameError({ message: `User with this ${email.current ? 'email' : 'phone number'} is not confirmed.`, code: 'USER_NOT_CONFIRMED' }); break;
          default:
            setUserNameError({ message: 'Something went wrong. Please try again.', code: "UNKNOWN_ERROR" });
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryParameters.get('email')) {
      setUserName(queryParameters.get('email') ?? '');
      handleAutoLogin(queryParameters.get('email') ?? '');
    }
    if (queryParameters.get('phone')) {
      setUserName(queryParameters.get('phone') ?? '');
      handleAutoLogin(queryParameters.get('email') ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  const handleUserNameSubmit = () => {
    setLoading(true);
    if (!isValidEmail(userName) && !isValidPhoneNumber(userName)) {
      setUserNameError({ message: 'Invalid email or phone number.', code: 'INVALID_FORMAT' });
      setLoading(false);
      return;
    }
    if (isValidEmail(userName)) email.current = userName;
    else {
      phone.current = parseUKPhoneNumber(userName);
    }
    logIn({ email: email.current, phone: phone.current })
      .then((response) => setSessionToken(response.data.data.session))
      .then(() => {
        setLoading(false);
        setOpen(true);
      })
      .catch((error) => {
        const errorResponseBody = JSON.parse(error.request.response);
        switch (errorResponseBody.error.code) {
          case "UserNotFoundException": setUserNameError({ message: `User with this ${email.current ? 'email' : 'phone number'} does not exist.`, code: 'UserNotFoundException' }); break;
          case "USER_NOT_CONFIRMED": setUserNameError({ message: `User with this ${email.current ? 'email' : 'phone number'} is not confirmed.`, code: 'USER_NOT_CONFIRMED' }); break;
          default:
            setUserNameError({ message: errorResponseBody.error.message, code: '' });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCodeSubmit = () => {
    if (!sessionToken) return;

    setLoading(true);

    if (isValidEmail(userName)) {
      email.current = userName;
    } else {
      phone.current = parseUKPhoneNumber(userName);
    }

    verifyLogIn(
      { email: email.current, phone: phone.current },
      code,
      sessionToken
    )
      .then((response) => {
        const accessToken = response.data.data.AccessToken ?? null;
        if (accessToken) {
          setAccessToken(accessToken);
          localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
          if (isValidEmail(userName)) {
            setEmail(userName);
            localStorage.setItem(LOGIN_TYPE, 'email');
            localStorage.setItem(USER_EMAIL_KEY, userName);
          } else {
            setPhone(userName);
            localStorage.setItem(LOGIN_TYPE, 'phone');
            localStorage.setItem(USER_PHONE_KEY, userName);
          }
          history.push(CHECK);
        } else {
          setOTPError({ message: 'Incorrect One Time Code', code: 'INCORRECT_CODE' });
        }
      })
      .catch((error) => {
        const errorResponseBody = JSON.parse(error.request.response);
        // setOTPError({ message: errorResponseBody.error.message, code: '' });
        switch (errorResponseBody.error.code) {
          case "CodeMismatchException":
            setOTPError({ message: "Incorrect code entered.", code: "INCORRECT_CODE" });
            break;
          case "ExpiredCodeException":
            setOTPError({ message: "Code has expired.", code: "EXPIRED_CODE" });
            break;
          case "LimitExceededException":
            setOTPError({ message: 'You have exceeded the limit of sending verification code. Please try again later.', code: "LimitExceededException" });
            break;
          default:
            setOTPError({ message: errorResponseBody.error.message, code: "UNKNOWN_ERROR" });
            break;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resendCode = () => {
    setOTPError(defaultError);
    setCode("");
    handleUserNameSubmit();
  };


  const clearLocalStorage = () => {
    localStorage.removeItem(LOGIN_TYPE);
    localStorage.removeItem(USER_EMAIL_KEY);
    localStorage.removeItem(USER_PHONE_KEY);
    localStorage.removeItem(USER);
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  const handleCreateAccount = () => {
    clearLocalStorage();
    if (isValidEmail(userName)) {
      localStorage.setItem(LOGIN_TYPE, 'email');
      localStorage.setItem(USER_EMAIL_KEY, userName);
      history.push(`${PRIMARY_CONTACT}?email=${userName}`);
    } else if (isValidPhoneNumber(parseUKPhoneNumber(userName))) {
      localStorage.setItem(LOGIN_TYPE, 'phone');
      localStorage.setItem(USER_PHONE_KEY, userName);
      history.push(`${PRIMARY_CONTACT}?phone=${userName}`);
    }
    setUserNameError({ message: 'Invalid email or phone number.', code: 'INVALID_FORMAT' });
  }

  const resolveUsernameButton = () => {
    if (userNameError.code === 'USER_NOT_CONFIRMED') {
      return {
        actionFn: () => history.push(VERIFICATION + '?confirmUsername=' + userName),
        buttonText: 'Click here to confirm.',
        icon: null
      }
    } else if (userNameError.code === 'UserNotFoundException') {
      return {
        actionFn: handleCreateAccount,
        buttonText: 'Create an account.',
      }
    }
  }

  return (
    <SignUpContainer image={null}>
      <Paper variant="outlined" className={classes.paper}>
        <Slogan text={<Typography align="center" className={classes.message}>
          Welcome to <span style={{ color: theme.palette.primary.main }}>Global Sports Data Technology</span>
        </Typography>} />
        <Collapse in={!open}>
          <Typography align="center" className={classes.description}>
            If you are new to GSDT, please create an account. Otherwise, please sign in.
          </Typography>
        </Collapse>
        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            !open ? handleUserNameSubmit() : handleCodeSubmit()
          }
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Collapse in={!open}>
                <TextField
                  error={!!userNameError.message}
                  fullWidth
                  helperText={<HelperText
                    errorMessage={userNameError.message}
                    isError={!!userNameError.message}
                    button={resolveUsernameButton()} />
                  }
                  id="username"
                  label="Email or Phone Number"
                  name="username"
                  inputProps={{
                    autoCapitalize: 'none',
                    autoCorrect: 'off',
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginRight: 0,
                    },
                  }}
                  onChange={(e) => {
                    if (!!userNameError.message) setUserNameError(defaultError);
                    setUserName(e.target.value);
                  }}
                  required
                  type="text"
                  value={userName}
                  variant="outlined"
                />
              </Collapse>
            </Grid>
            <Grid item xs={12}>
              <Collapse in={open}>
                <>
                  <Box>
                    {email.current ? <MailOutlineIcon className={classes.title} style={{ fontSize: '6em' }} />
                      : <SmartphoneIcon className={classes.title} style={{ fontSize: '6em' }} />}
                    <Typography align="center" className={classes.title}>Sign in code</Typography>
                  </Box>
                  <Divider />
                  {email.current ? <Typography align="left" className={classes.message}>
                    Please enter 4-digit code that was sent to {email.current || 'your email'}.
                  </Typography> : <Typography align="left" className={classes.message}>
                    Please enter 4-digit code that was sent to {phone.current || 'your phone via SMS'}.
                  </Typography>}

                  <OTPInput
                    autoFocus
                    length={4}
                    style={{ marginTop: 32, marginBottom: 32 }}
                    inputStyle={otpError.message ? { border: '1px solid red' } : {}}
                    className="otpContainer"
                    inputClassName="otpInput"
                    onChangeOTP={(e) => {
                      if (!!otpError.message) setOTPError(defaultError);
                      setCode(e);
                    }}
                  />
                  <HelperText
                    errorMessage={otpError.message}
                    isError={!!otpError.message} />
                  <Typography align="left" className={classes.otpQuestion}>
                    Did not receive the sign in code? <span className={classes.resendButton} onClick={resendCode}>Resend code.</span>
                  </Typography>
                </>
              </Collapse>
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Button
              className={classes.button}
              color="primary"
              fullWidth
              type="submit"
              disabled={loading}
              variant="contained"
            >
              {!open ? "Sign In" : "Submit Code"}
            </Button>
            {loading && <LinearProgress color="secondary" />}
          </Box>
        </form>
        <Collapse in={!open}>
          <Box marginY={4} textAlign="center" width="100%" position="relative">
            <Typography className={classes.or}>OR</Typography>
            <Divider />
          </Box>
          <Box width="100%">
            <Button
              className={classes.button}
              color="secondary"
              onClick={handleCreateAccount}
              variant="contained"
              disabled={loading}
              fullWidth
            >
              Create an account
            </Button>
          </Box>
        </Collapse>
      </Paper>
    </SignUpContainer>
  );
};
