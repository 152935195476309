import React, { FC } from "react";
import {
    makeStyles,
    Box,
    useTheme,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    textButton: {
        textDecoration: 'underline',
        fontWeight: 600,
        cursor: 'pointer',
        color: theme.palette.primary.main,
    }
}));

interface Props {
    errorMessage: string;
    helperText?: string;
    isError: boolean;
    button?: ActionButton;
}

interface ActionButton {
    actionFn: () => void,
    buttonText: string,
}

export const HelperText: FC<Props> = ({ errorMessage, helperText, isError, button }) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box style={{ color: isError ? theme.palette.error.main : theme.palette.text.secondary }}>
            {errorMessage || helperText}
            {" "}
            {isError && button && <span onClick={button?.actionFn} className={classes.textButton}>
                {button?.buttonText}
            </span>}
        </Box>
    );
};
